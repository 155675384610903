import React from 'react';
const Helper = {

    url: window.location.hostname.indexOf("localhost") != -1 ? 'https://system.testzentrumdemo.fybit.de' : 'https://system.'+(window.location.hostname.indexOf("localhost") == -1 ? window.location.hostname : "teststation-bernwardstr.de"),
    suburl: '/api',

    primary: '#e9e9e9',
    secondary: '#1372b8',
    tertiary: '#eb690b',

    address: [
        {domain: "testzentrum-nordheim.de", address: {map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2612.0380300785523!2d9.133891251939023!3d49.10491797921091!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479829754a1c2093%3A0xce0dace19b4bd0b5!2sLauffener%20Str.%2047%2C%2074226%20Nordheim!5e0!3m2!1sde!2sde!4v1645700383900!5m2!1sde!2sde", street: 'Lauffener Straße 47', zip: '74226', city: 'Nordheim', helper: ''}},
        {domain: "localhost", address: {map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2451.401865585606!2d10.160188415693142!3d52.09061747571362!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a54d7e814673a3%3A0x661712e48173a6b1!2sBindersche%20Str.%205%2C%2031188%20Holle!5e0!3m2!1sde!2sde!4v1638648286785!5m2!1sde!2sde", street: 'Hauptstr 59', zip: '23619', city: 'Mönkhagen', helper: ''}},
    ].filter(item => item.domain == window.location.hostname.toLowerCase())[0].address,

    opening_times: '08:00 - 18:00 Uhr',
    opening_date: 'Montag-Sonntag',

    booking_times: {
        "from": 7,
        "to": 18,
        "from1": 7,
        "to1": 18,
    }
}
export default Helper;
