import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';

import axios from 'axios';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import moment from "moment";
import {Calendar, utils} from "react-modern-calendar-datepicker";
import Helper from "../components/helper/helper";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const MehrLink = React.forwardRef((props, ref) => <RouterLink innerRef={ref} {...props} />);
function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}


var options = {}

const useStyles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        overflow: 'hidden',
        alignItems: 'center',
        justifyContent: 'center',

    },
    gridList: {
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
    titleBar: {
        background: "transparent"
    },

    icon: {
        backgroundColor: "#005D66",
        color: "#fff",
        padding: "5px",
        margin: "12px"
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        background: '#fff',
        padding: '20px'
    },
    inputField: {
        width: '100%',
        borderBottom: 0,
        backgroundColor: "#e9e9e9"
    },
    boxView: {
        width: '49%',
        marginRight: '1%',
        marginBottom: 20
    },
    boxViewFull: {
        width: '100%',
        marginRight: '1%',
        marginBottom: 20
    },
    boxViewsmall: {
        width: '33.5%',
        marginRight: '1%',
    },
    boxViewsmaller: {
        width: '14.5%',
        marginRight: '1%',
    },
    btnAdd: {
        padding: 0,
        margin: 0,
        fontSize: 35,
        width: 35,
        height: 35,
        minHeight: 0,
        minWidth: 0,
        backgroundColor: '#eb690b'
    },
    btnSubmit: {
        backgroundColor: '#eb690b',
        color: '#fff'
    }
});
const classes = useStyles;


class Openappointment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showData: true,
            allowDates: null,
            isLoaded: false,
            token: localStorage.getItem('access_token'),
            isDisconnected: false,
            scriptisLoaded: false,
            innerwidth: window.innerWidth,
            error: false,
            result: 'No result',
            results: [],
            page: 1,
            selectedDay: null,
            simulater: false,
            selected_day: moment().format("Y-MM-DD"),
            interval: 5,
        };

        if(this.state.token == null) {
            window.location.href = "/login";
        }
        if(this.state.token.toString().length < 10) {
            window.location.href = "/login";
        }

        this.loadMore = this.loadMore.bind(this);
        this.setSelectedDay = this.setSelectedDay.bind(this);
    }

    componentWillUnmount() {
        window.removeEventListener('online', this.handleConnectionChange);
        window.removeEventListener('offline', this.handleConnectionChange);
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    handleConnectionChange = () => {
        const condition = navigator.onLine ? 'online' : 'offline';
        console.log(condition);
        if (condition === 'online') {

        }else{
            this.setState({ isDisconnected: true });
        }
    }

    loadOrders() {

        const headers = {
            'Authorization': 'Bearer ' + this.state.token,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };

        // NOTE Post to HTTPS only in production
        axios.get(Helper.url+Helper.suburl+'/booking/today/'+this.state.selected_day+'?page='+this.state.page, {
            headers: headers
        }).then((response) => {
            console.log(response.data.dates)
            if(response.data.dates.length == 0) {
                this.setState({simulater: false,showData: false})
                alert("Keine weiteren Termine gefunden");
            }else{
                this.setState({results: response.data.dates, simulater: false, showData: true}, function() {
                    this.calcAllowDates()
                })
            }


        }).catch((errormsg) => {
            console.log("errormsg1",errormsg);
        });

    }

    updateDimensions() {
        this.setState({ innerwidth: window.innerWidth});
    }

    componentDidMount() {

        this.handleConnectionChange();
        window.addEventListener('online', this.handleConnectionChange);
        window.addEventListener('offline', this.handleConnectionChange);
        window.addEventListener("resize", this.updateDimensions.bind(this));
        this.loadOrders();




    }

    loadMore(){
        this.setState({page: this.state.page+1}, function() {this.loadOrders()});
    }

    setSelectedDay(date) {
        this.setState({simulater: true,selectedDay: date, selected_day: date['year'] + '-' + (date['month'] < 10 ? '0'+date['month'] : date['month']) + '-' + (date['day'] < 10 ? '0'+date['day'] : date['day'])}, function() {this.loadOrders()})
    }

    calcAllowDates() {

        var monday = [];
        var tuesday = [];
        var wednesday = [];
        var thursday = [];
        var friday = [];
        var saturday = [];
        var sunday = [];

        var x = this.state.interval; //minutes interval
        var tt = 0; // start time
        const time = moment().format("HH");
        const minute = moment().minute();
        for (var i=0;tt<24*60; i++) {
            var hh = Math.floor(tt/60); // getting hours of day in 0-24 format
            var mm = (tt%60); // getting minutes of the hour in 0-55 format


            //if(moment(this.state.selected_day).format('dddd') == "Friday" || moment(this.state.selected_day).format('dddd') == "Sunday" || moment(this.state.selected_day).format('dddd') == "Saturday") {
            if((hh > Helper.booking_times.from && hh < Helper.booking_times.to) || (hh > Helper.booking_times.from1 && hh < Helper.booking_times.to1)) {

                if(this.state.selected_day == moment().format("DDMM") || this.state.selected_day == moment().format("Y-MM-DD")) {
                    if(hh > time) {
                        monday[i] = (hh < 10 ? '0' + hh : hh)  + ':' + ("0" + mm).slice(-2); // pushing data in array in [00:00 - 12:00 AM/PM format]
                    }else{
                        console.log(hh +'=='+ time +'&&'+ minute +'>'+ mm);
                        if(hh == time && mm > minute) {
                            monday[i] = (hh < 10 ? '0' + hh : hh)  + ':' + ("0" + mm).slice(-2); // pushing data in array in [00:00 - 12:00 AM/PM format]
                        }
                    }
                }else {
                    monday[i] = (hh < 10 ? '0' + hh : hh)  + ':' + ("0" + mm).slice(-2); // pushing data in array in [00:00 - 12:00 AM/PM format]
                }

                tuesday[i] = (hh < 10 ? '0' + hh : hh) + ':' + ("0" + mm).slice(-2); // pushing data in array in [00:00 - 12:00 AM/PM format]

                wednesday[i] = (hh < 10 ? '0' + hh : hh) + ':' + ("0" + mm).slice(-2); // pushing data in array in [00:00 - 12:00 AM/PM format]

                thursday[i] = (hh < 10 ? '0' + hh : hh) + ':' + ("0" + mm).slice(-2); // pushing data in array in [00:00 - 12:00 AM/PM format]

                friday[i] = (hh < 10 ? '0' + hh : hh) + ':' + ("0" + mm).slice(-2); // pushing data in array in [00:00 - 12:00 AM/PM format]

                saturday[i] = (hh < 10 ? '0' + hh : hh) + ':' + ("0" + mm).slice(-2); // pushing data in array in [00:00 - 12:00 AM/PM format]

                sunday[i] = (hh < 10 ? '0' + hh : hh) + ':' + ("0" + mm).slice(-2); // pushing data in array in [00:00 - 12:00 AM/PM format]
            }
            // }



            tt = tt + x;
        }

        const list = [{
            0: monday
        }]

        this.setState({allowDates: list})
    }

    showRow(content, index) {
        const data = [];
        data.push(<Box style={{width: 35, float: 'left', paddingTop: 12}}></Box>);
        Object.values(content).map((content1, index1) => {
            data.push(<div className={"timbutto"} style={{float:'left'}}>{this.showLine(content1, index1)}</div>);
        });
        data.push(<Box style={{width: 35, float: 'left', paddingTop: 12}}></Box>);
        return data;
    }

    showLine(content, index) {
        const data = [];
        const {selected_day, daylist, openHours} = this.state;

        content.map((content1, index1) => {
            var ex = content1.split(':');
            var dp = this.state.selected_day.split('-');


            if(typeof this.state.results[dp[2]+dp[1]][content1] == "undefined"){
                data.push(<Box style={{width: 85, margin: 3, float: 'left'}}><Button color={ (this.state.selected_time == content1) ? "primary": "secondary"}  variant={"contained"}>{content1}&nbsp;&nbsp;&nbsp;</Button></Box>);
            }else {
                data.push(<Box style={{width: 85, margin: 3, float: 'left'}}><Button style={{backgroundColor: 'darkred', color: '#fff'}} variant={"contained"}>{content1}/{typeof this.state.results[dp[2]+dp[1]][content1] != "undefined" ? this.state.results[dp[2]+dp[1]][content1] : '' }</Button></Box>);
            }


        })
        //, display: parseInt(ex[0]) >= openHours[0] && parseInt(ex[0]) <= openHours[1] ? 'block' : 'none'
        return data;
    }

    showDates() {
        const {allowDates} = this.state;

        var data = [];
        if(allowDates != null){
            allowDates.map((content, index) => {
                data.push(this.showRow(allowDates[index]));
            });
        }
        return data;
    }

    // The render method contains the JSX code which will be compiled to HTML.
    render() {
        const { error, isLoaded, selectedDay, simulater } = this.state;
        const { classes } = this.props;

        const col = (this.state.innerwidth > 900) ? 8 : 2.5;

        if (isLoaded) {
            return <div style={{textAlign: "center", marginTop: "50%"}}>
                <CircularProgress disableShrink />
            </div>
        } else {

            const flexContainer = {

                padding: 0,
            };

            const langDE = {
                // months list by order
                months: [
                    'Januar',
                    'Februar',
                    'März',
                    'April',
                    'Mai',
                    'Juni',
                    'Juli',
                    'August',
                    'September',
                    'Oktober',
                    'November',
                    'Dezember',
                ],

                // week days by order
                weekDays: [
                    {
                        name: 'Montag',
                        short: 'M',
                    },
                    {
                        name: 'Dienstag',
                        short: 'D',
                    },
                    {
                        name: 'Mittwoch',
                        short: 'M',
                    },
                    {
                        name: 'Donnerstag',
                        short: 'D',
                    },
                    {
                        name: 'Freitag',
                        short: 'F',
                    },
                    {
                        name: 'Samstag',
                        short: 'S',
                        isWeekend: true,
                    },
                    {
                        name: 'Sonntag', // used for accessibility
                        short: 'S', // displayed at the top of days' rows
                        isWeekend: true, // is it a formal weekend or not?
                    },
                ],

                // just play around with this number between 0 and 6
                weekStartingIndex: 6,

                // return a { year: number, month: number, day: number } object
                getToday(gregorainTodayObject) {
                    return gregorainTodayObject;
                },

                // return a native JavaScript date here
                toNativeDate(date) {
                    return new Date(date.year, date.month - 1, date.day);
                },

                // return a number for date's month length
                getMonthLength(date) {
                    return new Date(date.year, date.month, 0).getDate();
                },

                // return a transformed digit to your locale
                transformDigit(digit) {
                    return digit;
                },

                // texts in the date picker
                nextMonth: 'nächster Monat',
                previousMonth: 'voriger Monat',
                openMonthSelector: 'Monatsauswahl',
                openYearSelector: 'Jahresauswahl',
                closeMonthSelector: 'Monatsauswahl schließen',
                closeYearSelector: 'schließen',
                defaultPlaceholder: 'Auswählen...',

                // for input range value
                from: 'from',
                to: 'to',


                // used for input value when multi dates are selected
                digitSeparator: ',',

                // if your provide -2 for example, year will be 2 digited
                yearLetterSkip: 0,

                // is your language rtl or ltr?
                isRtl: false,
            }

            return (
                <div className={"content"}>
                    <Typography variant="h1" component="h3" gutterBottom>
                        Termine
                    </Typography>

                    <div style={{float: 'left', width: window.innerWidth < 900 ? '100%' : '40%'}}>
                        <Calendar
                            minimumDate={utils().getToday()}
                            value={selectedDay}
                            onChange={this.setSelectedDay}
                            shouldHighlightWeekends
                            locale={langDE}
                            customDaysClassName={[
                                // here we add some CSS classes
                                //{ year: 2021, month: 4, day: 13, className: 'purpleDay' },
                                //{ year: 2021, month: 4, day: 14, className: 'orangeDay' },
                                //{ year: 2021, month: 4, day: 18, className: 'yellowDay' },
                                //{ year: 2021, month: 4, day: 26, className: 'navyBlueDay' },
                            ]}
                        />
                    </div>
                    <div style={{float: 'left', width: window.innerWidth < 900 ? '100%' : '60%'}}>
                        {
                            !this.state.showData ? null :
                            simulater ?
                                <div style={{textAlign: 'center', marginTop: 20}}><CircularProgress disableShrink /></div>
                                :
                                this.showDates()
                        }


                    </div>
                    <div style={{clear: 'both'}}></div>
                    {/*
                    <List aria-label="secondary mailbox folders" style={{textAlign: 'left'}}>
                        {this.state.results.map((label) =>
                        <ListItemLink onClick={(event) => window.location='/que/' + label.test.test_id}>
                            <ListItemText style={{fontSize: 14}}>
                                {label.test.test_id}: {moment(label.date).format("DD.MM.Y")} {label.time} Uhr
                            </ListItemText>
                        </ListItemLink>
                        )}

                    </List>
                    */}

                </div>
            )
        }

    }
}

export default withStyles(useStyles)(Openappointment);
