import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import ReactMarkdown from 'react-markdown'

import axios from 'axios';
import Helper from "../components/helper/helper";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


var options = {}

const useStyles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        overflow: 'hidden',
        alignItems: 'center',
        justifyContent: 'center',

    },
    gridList: {
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
    titleBar: {
        background: "transparent"
    },

    icon: {
        backgroundColor: "#005D66",
        color: "#fff",
        padding: "5px",
        margin: "12px"
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        background: '#fff',
        padding: '20px'
    },
    inputField: {
        width: '100%',
        borderBottom: 0,
        backgroundColor: "#e9e9e9"
    },
    boxView: {
        width: '49%',
        marginRight: '1%',
        marginBottom: 20
    },
    boxViewFull: {
        width: '100%',
        marginRight: '1%',
        marginBottom: 20
    },
    boxViewsmall: {
        width: '33.5%',
        marginRight: '1%',
    },
    boxViewsmaller: {
        width: '14.5%',
        marginRight: '1%',
    },
    btnAdd: {
        padding: 0,
        margin: 0,
        fontSize: 35,
        width: 35,
        height: 35,
        minHeight: 0,
        minWidth: 0,
        backgroundColor: Helper.secondary
    },
    btnSubmit: {
        backgroundColor: Helper.secondary,
        color: '#fff'
    }
});
const classes = useStyles;


class Impressum extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: true,
            results: null,
            isDisconnected: false,
            scriptisLoaded: false,
            innerwidth: window.innerWidth,
            error: false
        };

    }

    componentWillUnmount() {
        window.removeEventListener('online', this.handleConnectionChange);
        window.removeEventListener('offline', this.handleConnectionChange);
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    handleConnectionChange = () => {
        const condition = navigator.onLine ? 'online' : 'offline';
        console.log(condition);
        if (condition === 'online') {

        }else{
            this.setState({ isDisconnected: true });
        }
    }



    updateDimensions() {
        this.setState({ innerwidth: window.innerWidth});
    }

    componentDidMount() {
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };

        // NOTE Post to HTTPS only in production
        axios.get(Helper.url+Helper.suburl+'/global/content/imprint', {
            headers: headers
        }).then((response) => {
            this.setState({isLoaded: false, results:response.data})
        }).catch((errormsg) => {
            this.setState({isLoaded: false})
        });

        this.handleConnectionChange();
        window.addEventListener('online', this.handleConnectionChange);
        window.addEventListener('offline', this.handleConnectionChange);
        window.addEventListener("resize", this.updateDimensions.bind(this));

    }

    all(){

        return <>
            <Typography variant="h1" component="h3" gutterBottom>
                Impressum
            </Typography>
            {
                this.state.results == null ? null : <ReactMarkdown>{this.state.results.content}</ReactMarkdown>
            }
        </>
    }

    // The render method contains the JSX code which will be compiled to HTML.
    render() {
        const { error, isLoaded} = this.state;
        const { classes } = this.props;

        const col = (this.state.innerwidth > 900) ? 8 : 2.5;

        if (isLoaded) {
            return <div style={{textAlign: "center", marginTop: "50%"}}>
                <CircularProgress disableShrink />
            </div>
        } else {


            return (
                <div className={"content"}>
                    {
                        error ? <Alert severity="error" disabled={error} onClick={() => {this.setState({error: true});}}>This is an error message!</Alert>: null
                    }

                    {
                        this.all()
                    }
                </div>
            )
        }

    }
}

export default withStyles(useStyles)(Impressum);
