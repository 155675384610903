import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';

import axios from 'axios';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { CSVLink, CSVDownload } from "react-csv";
import moment from "moment";
import {Calendar, utils} from "react-modern-calendar-datepicker";
import Helper from "../components/helper/helper";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const MehrLink = React.forwardRef((props, ref) => <RouterLink innerRef={ref} {...props} />);
function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}


var options = {}

const useStyles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        overflow: 'hidden',
        alignItems: 'center',
        justifyContent: 'center',

    },
    gridList: {
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
    titleBar: {
        background: "transparent"
    },

    icon: {
        backgroundColor: "#005D66",
        color: "#fff",
        padding: "5px",
        margin: "12px"
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        background: '#fff',
        padding: '20px'
    },
    inputField: {
        width: '100%',
        borderBottom: 0,
        backgroundColor: "#e9e9e9"
    },
    boxView: {
        width: '49%',
        marginRight: '1%',
        marginBottom: 20
    },
    boxViewFull: {
        width: '100%',
        marginRight: '1%',
        marginBottom: 20
    },
    boxViewsmall: {
        width: '33.5%',
        marginRight: '1%',
    },
    boxViewsmaller: {
        width: '14.5%',
        marginRight: '1%',
    },
    btnAdd: {
        padding: 0,
        margin: 0,
        fontSize: 35,
        width: 35,
        height: 35,
        minHeight: 0,
        minWidth: 0,
        backgroundColor: '#eb690b'
    },
    btnSubmit: {
        backgroundColor: '#eb690b',
        color: '#fff'
    }
});
const classes = useStyles;


class Customer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: true,
            token: localStorage.getItem('access_token'),
            isDisconnected: false,
            scriptisLoaded: false,
            innerwidth: window.innerWidth,
            error: false,
            result: 'No result',
            results: [],
            page: 1,
            csvData: [],
            download: false,
            selected_day: moment().format("Y-MM-DD"),
            today: 0,
            today_pos: 0,
            month: 0
        };

        if(this.state.token == null) {
            window.location.href = "/login";
        }
        if(this.state.token.toString().length < 10) {
            window.location.href = "/login";
        }

        this.loadMore = this.loadMore.bind(this);
        this.setSelectedDay = this.setSelectedDay.bind(this);
    }

    componentWillUnmount() {
        window.removeEventListener('online', this.handleConnectionChange);
        window.removeEventListener('offline', this.handleConnectionChange);
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    handleConnectionChange = () => {
        const condition = navigator.onLine ? 'online' : 'offline';
        console.log(condition);
        if (condition === 'online') {

        }else{
            this.setState({ isDisconnected: true });
        }
    }

    loadOrders() {

        const headers = {
            'Authorization': 'Bearer ' + this.state.token,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };


        // NOTE Post to HTTPS only in production
        axios.get(Helper.url+Helper.suburl+'/customer/index?date='+this.state.selected_day+'&page='+this.state.page, {
            headers: headers
        }).then((response) => {
            console.log(response.data.kunden.data)
            if(response.data.kunden.data.length == 0) {
                alert("Keine weiteren Kunden gefunden");
            }
            this.setState({isLoaded: false, today_pos: response.data.today_pos,today:response.data.today, month: response.data.month})
            response.data.kunden.data.map((label) =>
                this.state.results.push(label)
            );

            response.data.kunden.data.map((label) =>
                this.state.csvData.push(
                    {'Testdatum': label.last == null ? null : moment(label.last.updated_at).format("DD.MM.Y HH:MM"),'Vorname': label.profile.firstname, 'Nachname': label.profile.lastname, 'Adresse': label.profile.street + ', ' + label.profile.zip + ' ' + label.profile.city, 'Telefon': label.profile.telephone, 'E-Mail': label.email, 'Geburtstag': label.profile.bday})
            );



            this.setState({isLoaded: false, today_pos: response.data.today_pos,today:response.data.today, month: response.data.month, results: this.state.results})
        }).catch((errormsg) => {
            this.setState({isLoaded: false})
        });

    }

    updateDimensions() {
        this.setState({ innerwidth: window.innerWidth});
    }

    componentDidMount() {

        this.handleConnectionChange();
        window.addEventListener('online', this.handleConnectionChange);
        window.addEventListener('offline', this.handleConnectionChange);
        window.addEventListener("resize", this.updateDimensions.bind(this));

        this.loadOrders();
    }

    setSelectedDay(date) {
        this.setState({results: [],csvData: [], isLoaded: true, selectedDay: date, selected_day: date['year'] + '-' + (date['month'] < 10 ? '0'+date['month'] : date['month']) + '-' + (date['day'] < 10 ? '0'+date['day'] : date['day'])}, function() {this.loadOrders()})
    }

    loadMore(){
        this.setState({page: this.state.page+1}, function() {this.loadOrders()});
    }

    importCSV(e) {

        const headers = {
            'Authorization': 'Bearer ' + this.state.token,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };

        const formData = new FormData();
        formData.append(
            "import_file",
            "file"
        );
        // Update the formData object
        formData.append(
            "import.csv",
            e.target.files[0],
            e.target.files[0].name
        );

        // Request made to the backend api
        // Send formData object
        axios.post(Helper.url+Helper.suburl+'/customer/import', formData, {
            headers: headers
        }).then((response) => {
          alert("Import durchgeführt");
          window.location.reload()
        });
    }

    // The render method contains the JSX code which will be compiled to HTML.
    render() {
        const { error, isLoaded} = this.state;
        const { classes } = this.props;

        const col = (this.state.innerwidth > 900) ? 8 : 2.5;

        if (isLoaded) {
            return <div style={{textAlign: "center", marginTop: "50%"}}>
                <CircularProgress disableShrink />
            </div>
        } else {

            const flexContainer = {

                padding: 0,
            };

            const langDE = {
                // months list by order
                months: [
                    'Januar',
                    'Februar',
                    'März',
                    'April',
                    'Mai',
                    'Juni',
                    'Juli',
                    'August',
                    'September',
                    'Oktober',
                    'November',
                    'Dezember',
                ],

                // week days by order
                weekDays: [
                    {
                        name: 'Montag',
                        short: 'M',
                    },
                    {
                        name: 'Dienstag',
                        short: 'D',
                    },
                    {
                        name: 'Mittwoch',
                        short: 'M',
                    },
                    {
                        name: 'Donnerstag',
                        short: 'D',
                    },
                    {
                        name: 'Freitag',
                        short: 'F',
                    },
                    {
                        name: 'Samstag',
                        short: 'S',
                        isWeekend: true,
                    },
                    {
                        name: 'Sonntag', // used for accessibility
                        short: 'S', // displayed at the top of days' rows
                        isWeekend: true, // is it a formal weekend or not?
                    },
                ],

                // just play around with this number between 0 and 6
                weekStartingIndex: 6,

                // return a { year: number, month: number, day: number } object
                getToday(gregorainTodayObject) {
                    return gregorainTodayObject;
                },

                // return a native JavaScript date here
                toNativeDate(date) {
                    return new Date(date.year, date.month - 1, date.day);
                },

                // return a number for date's month length
                getMonthLength(date) {
                    return new Date(date.year, date.month, 0).getDate();
                },

                // return a transformed digit to your locale
                transformDigit(digit) {
                    return digit;
                },

                // texts in the date picker
                nextMonth: 'nächster Monat',
                previousMonth: 'voriger Monat',
                openMonthSelector: 'Monatsauswahl',
                openYearSelector: 'Jahresauswahl',
                closeMonthSelector: 'Monatsauswahl schließen',
                closeYearSelector: 'schließen',
                defaultPlaceholder: 'Auswählen...',

                // for input range value
                from: 'from',
                to: 'to',


                // used for input value when multi dates are selected
                digitSeparator: ',',

                // if your provide -2 for example, year will be 2 digited
                yearLetterSkip: 0,

                // is your language rtl or ltr?
                isRtl: false,
            }

            return (
                <div className={"content"}>
                    <Typography variant="h1" component="h3" gutterBottom>
                        Kunden
                    </Typography>

                    <div>
                        negative Kunden heute: {this.state.today}<br/>
                        positive Kunden heute: {this.state.today_pos}<br/>
                        Kunden diesen Monat: {this.state.month}
                    </div>
                    <div style={{float: 'left', width: window.innerWidth < 900 ? '100%' :  '30%'}}>

                    <Calendar
                        maximumDate={utils().getToday()}
                        value={this.state.selectedDay}
                        onChange={this.setSelectedDay}
                        shouldHighlightWeekends
                        locale={langDE}
                        customDaysClassName={[
                            // here we add some CSS classes
                            //{ year: 2021, month: 4, day: 13, className: 'purpleDay' },
                            //{ year: 2021, month: 4, day: 14, className: 'orangeDay' },
                            //{ year: 2021, month: 4, day: 18, className: 'yellowDay' },
                            //{ year: 2021, month: 4, day: 26, className: 'navyBlueDay' },
                        ]}
                    />
                    </div>
                    <div style={{float: 'left', width: window.innerWidth < 900 ? '100%' :  '70%'}}>

                        <List aria-label="secondary mailbox folders" style={{textAlign: 'left'}}>
                            {this.state.results.map((label) =>
                            <ListItemLink onClick={(event) => window.location="/customer/"+label.id}>
                                <ListItemText style={{fontSize: 14}}>
                                    {label.id}: {label.profile.firstname} {label.profile.lastname} {label.profile.phone} {label.email}, {label.profile.street} {label.profile.zip} {label.profile.city}
                                    <br />
                                    {
                                        (label.last != null ? "getestet am "+moment(label.last.updated_at).format("DD-MM-Y HH:MM")+", "+(label.last.result == 0 ? "negativ" : "positiv"): null)
                                    }
                                </ListItemText>
                            </ListItemLink>
                            )}

                        </List>
                    </div><div style={{clear: 'both'}}></div>
                    <Box style={{paddingBottom: 40}}>
                        <Button onClick={() => this.loadMore()} full color={"primary"} style={{width: '300px', backgroundColor: Helper.secondary, color: '#fff'}}>
                            Weitere Anzeigen
                        </Button>


                        <input type={"file"} id={"import_file"} style={{display: 'none'}} onChange={ (e) => this.importCSV(e)}/>


                        <Button full onClick={() => this.setState({download: true})} color={"primary"} style={{float:'right',width: '300px', backgroundColor: '#000', color: '#fff'}}>
                            Exportieren
                        </Button>

                        <Button full onClick={() => document.getElementById("import_file").click()} color={"primary"} style={{float:'right',width: '300px', backgroundColor: '#ddd', color: '#000'}}>
                            Datenimport
                        </Button>

                        {
                            this.state.download ? <CSVDownload data={this.state.csvData} target="_blank" /> : null
                        }
                    </Box>
                </div>
            )
        }

    }
}

export default withStyles(useStyles)(Customer);
