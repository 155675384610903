import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Router } from 'react-router-dom';
import history from './services/history';
import Routes from './routes';
import Header from './components/helper/header';
import Footer from './components/helper/footer';

ReactDOM.render(
  <React.StrictMode>
      <Router history={history}>
          <Header/>
          <Routes/>
          <Footer/>
      </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

