import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink } from 'react-router-dom';
import Slide from '@material-ui/core/Slide';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Helper from "../components/helper/helper";
import DatePicker from "react-multi-date-picker";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import RestorePageIcon from '@material-ui/icons/RestorePage';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import FileOpenIcon from '@material-ui/icons/Print';
import moment from "moment";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const MehrLink = React.forwardRef((props, ref) => <RouterLink innerRef={ref} {...props} />);
function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}


var options = {}

const useStyles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        overflow: 'hidden',
        alignItems: 'center',
        justifyContent: 'center',

    },
    gridList: {
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
    titleBar: {
        background: "transparent"
    },

    icon: {
        backgroundColor: "#005D66",
        color: "#fff",
        padding: "5px",
        margin: "12px"
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        background: '#fff',
        padding: '20px'
    },
    inputField: {
        width: '100%',
        borderBottom: 0,
        backgroundColor: "#e9e9e9"
    },
    boxView: {
        width: '49%',
        marginRight: '1%',
        marginBottom: 20
    },
    boxViewFull: {
        width: '100%',
        marginRight: '1%',
        marginBottom: 20
    },
    boxViewsmall: {
        width: '33.5%',
        marginRight: '1%',
    },
    boxViewsmaller: {
        width: '14.5%',
        marginRight: '1%',
    },
    btnAdd: {
        padding: 0,
        margin: 0,
        fontSize: 35,
        width: 35,
        height: 35,
        minHeight: 0,
        minWidth: 0,
        backgroundColor: Helper.secondary
    },
    btnSubmit: {
        backgroundColor: Helper.secondary,
        color: '#fff'
    }
});
const classes = useStyles;


class Account extends Component {

    constructor(props) {
        super(props);
        this.state = {

            token: localStorage.getItem('access_token'),
                labor: localStorage.getItem('labor') != 'Ja' ? 0: 1,
            admin: localStorage.getItem('admin') != 'Ja' ? 0: 1,
            isDisconnected: false,
            scriptisLoaded: false,
            innerwidth: window.innerWidth,
            email: null,
            password: null,
            error: false,
            result: 'No result',
            dates: null,
            rows: [],
            rows_backup: [],
            opentest: 0,
            tests: 0,
            neg_tests: 0,
            pos_tests: 0,
            anmeldungen: 0,
            filter: "",
            isLoading: true,
        };

        if(this.state.token == null) {
            window.location.href = "/login";
        }
        if(this.state.token.toString().length < 10) {
            window.location.href = "/login";
        }
        this.myRef = React.createRef();
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillUnmount() {
        window.removeEventListener('online', this.handleConnectionChange);
        window.removeEventListener('offline', this.handleConnectionChange);
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    handleConnectionChange = () => {
        const condition = navigator.onLine ? 'online' : 'offline';
        console.log(condition);
        if (condition === 'online') {

        }else{
            this.setState({ isDisconnected: true });
        }
    }



    updateDimensions() {
        this.setState({ innerwidth: window.innerWidth});
    }

    componentDidMount() {
        this.load();
        this.handleConnectionChange();
        window.addEventListener('online', this.handleConnectionChange);
        window.addEventListener('offline', this.handleConnectionChange);
        window.addEventListener("resize", this.updateDimensions.bind(this));

    }

    load() {
        let date_from = new Date().toLocaleDateString();
        let date_to = new Date().toLocaleDateString();
        if(this.state.dates != null) {
            date_from = this.state.dates[0].format("YYYY-MM-DD");

            if(this.state.dates.length == 2){
                date_to = this.state.dates[1].format("YYYY-MM-DD");
            }
        }

        const headers = {
            'Authorization': 'Bearer ' + this.state.token,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };

        // NOTE Post to HTTPS only in production
        axios.post(Helper.url+Helper.suburl+'/dashboard?date_from='+date_from+"&date_to="+date_to, {
            date_from: this.state.date_from,
            date_to: this.state.date_to,
        }, {
            headers: headers
        }).then((response) => {
            console.log("responseresponse",response);
            if(response.status === 200){
                let reuslts = ["negativ", "positiv"];
                let testsdata = [];
                testsdata["open"] = "nicht gescannt";
                testsdata["progress"] = "gescannt";
                let rowLine = [];
                if(response.data.user.length > 0) {
                    response.data.user.map(item =>
                    {
                        let min = ""
                        if(item.testopentrashed != null) {
                            if(item.testopentrashed.status == "progress") {
                                var diff = Math.abs(new Date() - new Date(item.testopentrashed.updated_at));
                                min = Math.floor((diff/1000)/60);
                            }
                        }


                        rowLine.push({min: min, created_at: moment(item.testopentrashed.rooms_booking.date).format("DD.MM.Y")+" "+item.testopentrashed.rooms_booking.time, testid: item.testopentrashed.id,test_id: item.testopentrashed.test_id, name: item.profile.firstname + " " + item.profile.lastname, email: item.email, result: (item.testopentrashed == null) ? '' : item.testopentrashed.deleted_at != null ? "-" : (item.testopentrashed.status == "send" ? "gesendet (" + reuslts[item.testopentrashed.result]+")" : item.testopentrashed.status == "done" ? "um "+moment(item.testopentrashed.send_at).format("HH:mm")+" Uhr ("+reuslts[item.testopentrashed.result]+")": testsdata[item.testopentrashed.status]), status: item.testopentrashed == null ? "angemeldet" : item.testopentrashed.deleted_at != null ? "storniert" : item.testopentrashed.status == "done" || item.testopentrashed.status == "send" ? "getestet" : item.testopentrashed.status == "open" ? "-": "im Test"})
                    }

                    );
                }


                this.setState({opentest: response.data.opentest,
                    tests: response.data.tests,
                    neg_tests:  response.data.neg_tests,
                    pos_tests: response.data.pos_tests,
                    rows: rowLine,
                    isLoading: false,
                    rows_backup: rowLine,
                    anmeldungen: response.data.anmeldungen})
            }else{
                //alert(response.status);
            }
        })
    }

    handleSubmit1(){
        //event.preventDefault();
        localStorage.setItem('access_token', "x");
        window.location.href = "/";
    }

    handleSubmit(event){
        event.preventDefault();
        /*this.setState({
            loading: true,
        });*/
        const headers = {
            //'Authorization': 'Bearer ' + token,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };

        // NOTE Post to HTTPS only in production
        axios.post(Helper.url+Helper.suburl+'/auth/login', {
            email: this.state.email,
            password: this.state.password,
        }, {
            headers: headers
        }).then((response) => {
            if(response.status === 200){
                if(response.data.user.employee == 0) {
                    localStorage.setItem('access_token', response.data.access_token);
                    window.location.href = "/scanner";
                }else{
                    alert("nur Zugriff für Mitarbeiter");
                }

               // window.location.href = "/";
            }else{
                //alert(response.status);
            }
        }).catch((errormsg) => {
            console.log(errormsg);
            if(typeof errormsg.response == "undefined") {
                this.setState({
                    loading: false,
                    error: true
                });
            }else{
                if(errormsg.response.status===404){

                    this.setState({
                        loading: false,
                        error: true
                    });
                }else{
                    //this.setAlertErrorVisible();
                    console.log("asdasd");
                    this.setState({
                        loading: false,
                        error: true
                    });
                }
            }

        });
    }


    handleScan = data => {
        if (data) {

        }
    }
    handleError = err => {
        console.error(err)
    }

    clean() {
        localStorage.clear();
        window.location.href = "/login"
    }

    filterit(type) {
        if(this.state.filter == type) {
            type="";
        }

        let newRow = [];
        this.setState({isLoading: true,rows: []})

        if(type == "opentest") {
            this.state.rows_backup.map(item => {

                if(item.result == ("gescannt")) {

                    newRow.push(item);
                }

            });

        }else if(type == "neg_tests") {

            this.state.rows_backup.map(item => {
                if(item.result.indexOf("negativ") != -1) {
                    newRow.push(item);
                }
            });
        }else if(type == "pos_tests") {

            this.state.rows_backup.map(item => {
                if(item.result.indexOf("positiv") != -1) {
                    newRow.push(item);
                }
            });
        }else if(type == "anmeldungen") {

            this.state.rows_backup.map(item => {
                if(item.result.indexOf("nicht gescannt") != -1) {
                    newRow.push(item);
                }
            });
        }else {
            newRow = this.state.rows_backup;
        }

        this.state.rows = newRow;
        this.setState({isLoading: false, rows: this.state.rows, filter: type}, function () {
            //(this.tableRef.current.onQueryChange()
            console.log(this.myRef);
        })
    }

    handleStatus(status, id){
        this.setState({
            send: true,
        });
        const headers = {
            'Authorization': 'Bearer ' + this.state.token,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };

        // NOTE Post to HTTPS only in production
        axios.post(Helper.url+Helper.suburl+'/booking/handle/'+ id, {
            status: status
        }, {
            headers: headers
        }).then((response) => {
            if(response.status === 200){
                alert("gelöscht");
                this.setState({
                    send: false,
                });

                window.location.reload();
            }else{
                this.setState({
                    send: false,
                });
            }
        }).catch((errormsg) => {
            this.setState({
                loading: false,
                error: true,
                send: false
            });
            if(typeof errormsg.response == "undefined") {
                this.setState({
                    loading: false,
                    error: true,
                    send: false,
                });
            }else{
                if(errormsg.response.status===404){

                    this.setState({
                        loading: false,
                        error: true,
                        send: false,
                    });
                }else{
                    //this.setAlertErrorVisible();

                    this.setState({
                        loading: false,
                        error: true,
                        send: false,
                    });
                }
            }

        });
    }

    resend(id) {
        const headers = {
            'Authorization': 'Bearer ' + this.state.token,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };

        // NOTE Post to HTTPS only in production
        axios.post(Helper.url+Helper.suburl+'/dashboard/resend', {
            test_id: id
        }, {
            headers: headers
        }).then((response) => {
           alert("E-Mail wurde erneut verschickt.")
        });
    }

    // The render method contains the JSX code which will be compiled to HTML.
    render() {
        const { error, isLoaded, rows} = this.state;
        const { classes } = this.props;

        const col = (this.state.innerwidth > 900) ? 8 : 2.5;

        let menu_width = "20%";
        let content_width = "75%";
        let box_width = "18%";

        if(this.state.innerwidth < 700) {
            menu_width = "100%";
            content_width = "100%";
            box_width = "45%";
        }else if(this.state.innerwidth < 1000) {
            box_width = "30%";
        }

        if (isLoaded) {
            return <div style={{textAlign: "center", marginTop: "50%"}}>
                <CircularProgress disableShrink />
            </div>
        } else {

            const flexContainer = {
                textAlign: "center",
                padding: 0,
            };

            return (
                <div className={"content"}>
                    <Typography variant="h1" component="h3" gutterBottom>
                        Mein Konto
                    </Typography>
                    <div style={{width: menu_width, float: "left", marginTop: 10}}>
                        <List component="nav" aria-label="secondary mailbox folders" style={flexContainer}>

                        <ListItemLink onClick={(event) => window.location="/checkin"} style={{flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                            <ListItemText style={{fontSize: 14}}>
                                Manueller Check-in
                            </ListItemText>
                        </ListItemLink>
                        <ListItemLink onClick={(event) => window.location="/opencustomer"} style={{flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                            <ListItemText style={{fontSize: 14}}>
                                offene Kunden
                            </ListItemText>
                        </ListItemLink>
                        <ListItemLink onClick={(event) => window.location="/findcustomer"} style={{flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                            <ListItemText style={{fontSize: 14}}>
                                Termin finden
                            </ListItemText>
                        </ListItemLink>
                        {
                            this.state.admin == 0 ? '' :

                                <ListItemLink onClick={(event) => window.location="/customer"} style={{flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                    <ListItemText style={{fontSize: 14}}>
                                        Kunden
                                    </ListItemText>
                                </ListItemLink>
                        }
                        <ListItemLink onClick={(event) => window.location="/openappointment"} style={{flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                            <ListItemText style={{fontSize: 14}}>
                                Termine
                            </ListItemText>
                        </ListItemLink>
                        <ListItemLink onClick={(event) => window.location="/qu"} style={{flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                            <ListItemText style={{fontSize: 14}}>
                                Aufträge
                            </ListItemText>
                        </ListItemLink>

                        <ListItemLink onClick={(event) => window.location="/scanner"} style={{flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                            <ListItemText style={{fontSize: 14}}>
                                QR Scanner
                            </ListItemText>
                        </ListItemLink>

                        {
                            this.state.admin == 0 ? '' :

                                <ListItemLink onClick={(event) => window.location="/contents"} style={{flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                    <ListItemText style={{fontSize: 14}}>
                                        Inhalte
                                    </ListItemText>
                                </ListItemLink>
                        }

                        {
                            this.state.admin == 0 ? '' :
                                <ListItemLink onClick={(event) => window.location = "/users"} style={{
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <ListItemText style={{fontSize: 14}}>
                                        Zugänge
                                    </ListItemText>
                                </ListItemLink>
                        }


                        {
                            this.state.admin == 0 ? '' :

                                <ListItemLink onClick={(event) => window.location="/settingsdata"} style={{flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                    <ListItemText style={{fontSize: 14}}>
                                        Einstellungen
                                    </ListItemText>
                                </ListItemLink>
                        }

                        <ListItemLink onClick={(event) => this.clean()} style={{flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                            <ListItemText style={{fontSize: 14, color: 'red'}}>
                                Abmelden
                            </ListItemText>
                        </ListItemLink>
                    </List>
                    </div>
                    <div style={{width: content_width, float: "left"}}>
                        <div>
                            <h2 style={{float: "left"}}>Dashboard -
                                {
                                    this.state.dates == null ? " heute" : " "+this.state.dates[0].format("DD.MM.YYYY")
                                 }
                                {
                                    this.state.dates == null ? null : typeof this.state.dates[1] != "undefined" ? " - "+this.state.dates[1].format("DD.MM.YYYY") : null
                                }
                            </h2>
                            <div style={{float: "right"}}>
                                Datum
                                <DatePicker  onChange={dateObjects => this.setState({dates: dateObjects}, function() {this.load()})} range value={this.state.dates == null ? new Date() : this.state.dates} style={{marginLeft:10,padding: 10, borderWidth: 1, borderRadius: 5, borderColor: Helper.secondary}}/>
                            </div>
                            <div style={{clear: "both"}}></div>
                        </div>
                        {
                            (this.state.isLoaded) ? <div style={{textAlign: "center", marginTop: "50%"}}>
                                    <CircularProgress disableShrink />
                                </div>
                                :
                            <div>
                                <div style={{flexDirection: "row", marginBottom: 100}}>



                                    <div onClick={ () => this.filterit("anmeldungen")} style={{cursor: "pointer", width: box_width, marginBottom: 10,height: 80, marginRight: "1%",borderStyle: "solid", float:"left", borderWidth: 2, borderRadius: 5, borderColor: Helper.secondary}}>
                                        <div style={{padding: 10}}>
                                            <p style={{margin: 0, padding:0}}>Anmeldungen</p>
                                            <p style={{float:"left", margin: 0, padding:0, fontSize: 30, fontWeight: "bold", color: Helper.secondary}}>
                                                {this.state.anmeldungen}
                                            </p>
                                            <p style={{margin: 0, padding:0, paddingTop: 10,color: "#000", float: "right"}}>
                                                <small>{this.state.filter == "anmeldungen" ? "gefiltert" : ""}</small>
                                            </p>
                                            <div style={{clear: "both"}}></div>
                                        </div>
                                    </div>

                                    <div onClick={ () => this.filterit("opentest")} style={{cursor: "pointer", width: box_width, marginBottom: 10,height: 80, marginRight: "1%",borderStyle: "solid", float:"left", borderWidth: 2, borderRadius: 5, borderColor: Helper.secondary}}>
                                        <div style={{padding: 10}}>
                                            <p style={{margin: 0, padding:0}}>offene Tests</p>
                                            <p style={{float:"left", margin: 0, padding:0, fontSize: 30, fontWeight: "bold", color: Helper.secondary}}>
                                                {this.state.opentest}
                                            </p>
                                            <p style={{margin: 0, padding:0, paddingTop: 10,color: "#000", float: "right"}}>
                                                <small>{this.state.filter == "opentest" ? "gefiltert" : ""}</small>
                                            </p>
                                            <div style={{clear: "both"}}></div>
                                        </div>
                                    </div>

                                    <div onClick={ () => this.filterit("tests")} style={{cursor: "pointer", width: box_width, marginBottom: 10,height: 80, marginRight: "1%",borderStyle: "solid", float:"left", borderWidth: 2, borderRadius: 5, borderColor: Helper.secondary}}>
                                        <div style={{padding: 10}}>
                                            <p style={{margin: 0, padding:0}}>heutige Tests</p>
                                            <p style={{float:"left", margin: 0, padding:0, fontSize: 30, fontWeight: "bold", color: Helper.secondary}}>
                                                {this.state.tests}
                                            </p>
                                            <p style={{margin: 0, padding:0, paddingTop: 10,color: "#000", float: "right"}}>
                                                <small>{this.state.filter == "tests" ? "gefiltert" : ""}</small>
                                            </p>
                                            <div style={{clear: "both"}}></div>
                                        </div>
                                    </div>

                                    <div onClick={ () => this.filterit("neg_tests")} style={{cursor: "pointer", width: box_width, marginBottom: 10,height: 80, marginRight: "1%",borderStyle: "solid", float:"left", borderWidth: 2, borderRadius: 5, borderColor: Helper.secondary}}>
                                        <div style={{padding: 10}}>
                                            <p style={{margin: 0, padding:0}}>negative Tests</p>
                                            <p style={{float:"left", margin: 0, padding:0, fontSize: 30, fontWeight: "bold", color: Helper.secondary}}>
                                                {this.state.neg_tests}
                                            </p>
                                            <p style={{margin: 0, padding:0, paddingTop: 10,color: "#000", float: "right"}}>
                                                <small>{this.state.filter == "neg_tests" ? "gefiltert" : ""}</small>
                                            </p>
                                            <div style={{clear: "both"}}></div>
                                        </div>
                                    </div>

                                    <div onClick={ () => this.filterit("pos_tests")} style={{cursor: "pointer", width: box_width, marginBottom: 10,height: 80, marginRight: "1%",borderStyle: "solid", float:"left", borderWidth: 2, borderRadius: 5, borderColor: Helper.secondary}}>
                                        <div style={{padding: 10}}>
                                            <p style={{margin: 0, padding:0}}>positive Tests</p>
                                            <p style={{float:"left", margin: 0, padding:0, fontSize: 30, fontWeight: "bold", color: Helper.secondary}}>
                                                {this.state.pos_tests}
                                            </p>
                                            <p style={{margin: 0, padding:0, paddingTop: 10,color: "#000", float: "right"}}>
                                                <small>{this.state.filter == "pos_tests" ? "gefiltert" : ""}</small>
                                            </p>
                                            <div style={{clear: "both"}}></div>

                                        </div>
                                    </div>
                                </div>

                                <TableContainer component={Paper} ref={(ref) => {
                                    this.myRef = ref
                                }}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table" >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Anmeldung</TableCell>
                                                <TableCell>Name</TableCell>
                                                <TableCell>E-Mail</TableCell>
                                                <TableCell>Testergebnis</TableCell>
                                                <TableCell>Status</TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            {rows.map((rowLine) => (
                                                <TableRow
                                                    key={rowLine.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {rowLine.created_at}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        <a href={"/que/"+rowLine.test_id} target={"_blank"}>{rowLine.name}</a>
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {rowLine.email}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {rowLine.result} {rowLine.min != "" ? "("+rowLine.min+"min)" : null}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {rowLine.status}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {
                                                            rowLine.result.indexOf("gesendet") != -1  || rowLine.result.indexOf("gesendet") != -1 ?
                                                                <RestorePageIcon style={{cursor: "pointer"}} onClick={() => this.resend(rowLine.testid)} />
                                                                :
                                                                null
                                                        }

                                                        {
                                                            rowLine.result.indexOf("gesendet") != -1  || rowLine.result.indexOf("gesendet") != -1 ?
                                                                <a href={Helper.url+"/api/result/" + rowLine.testid + "/get"}><FileOpenIcon /></a>
                                                                :
                                                                null
                                                        }

                                                        {rowLine.result.indexOf("gesendet") != -1 || rowLine.result.indexOf("gesendet") != -1 ?
                                                                null
                                                            :
                                                            <DeleteOutlineIcon style={{cursor: "pointer"}} onClick={() => (window.confirm("Bist du sicher das du den Test stornieren möchtest?")) ? this.handleStatus("none", rowLine.test_id) : ''} />
                                                        }



                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        }

                    </div>
                    <div style={{clear:"both"}}></div>
                </div>
            )
        }

    }
}

export default withStyles(useStyles)(Account);
