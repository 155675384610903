import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';

import axios from 'axios';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Helper from "../components/helper/helper";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const MehrLink = React.forwardRef((props, ref) => <RouterLink innerRef={ref} {...props} />);
function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}


var options = {}

const useStyles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        overflow: 'hidden',
        alignItems: 'center',
        justifyContent: 'center',

    },
    gridList: {
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
    titleBar: {
        background: "transparent"
    },

    icon: {
        backgroundColor: "#005D66",
        color: "#fff",
        padding: "5px",
        margin: "12px"
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        background: '#fff',
        padding: '20px'
    },
    inputField: {
        width: '100%',
        borderBottom: 0,
        backgroundColor: "#e9e9e9"
    },
    boxView: {
        width: '49%',
        marginRight: '1%',
        marginBottom: 20
    },
    boxViewFull: {
        width: '100%',
        marginRight: '1%',
        marginBottom: 20
    },
    boxViewsmall: {
        width: '33.5%',
        marginRight: '1%',
    },
    boxViewsmaller: {
        width: '14.5%',
        marginRight: '1%',
    },
    btnAdd: {
        padding: 0,
        margin: 0,
        fontSize: 35,
        width: 35,
        height: 35,
        minHeight: 0,
        minWidth: 0,
        backgroundColor: '#eb690b'
    },
    btnSubmit: {
        backgroundColor: '#eb690b',
        color: '#fff'
    }
});
const classes = useStyles;


class Opencustomer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            token: localStorage.getItem('access_token'),
            isDisconnected: false,
            scriptisLoaded: false,
            innerwidth: window.innerWidth,
            error: false,
            result: 'No result',
            results: [],
            page: 1
        };

        if(this.state.token == null) {
            window.location.href = "/login";
        }
        if(this.state.token.toString().length < 10) {
            window.location.href = "/login";
        }

        this.loadMore = this.loadMore.bind(this);
        this.updateOrders = this.updateOrders.bind(this);
    }

    componentWillUnmount() {
        window.removeEventListener('online', this.handleConnectionChange);
        window.removeEventListener('offline', this.handleConnectionChange);
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    handleConnectionChange = () => {
        const condition = navigator.onLine ? 'online' : 'offline';
        console.log(condition);
        if (condition === 'online') {

        }else{
            this.setState({ isDisconnected: true });
        }
    }

    updateOrders() {



        this.state.results.map(label => {
            label.test.min = label.test.min+1;
        })
        this.setState({results: this.state.results})
    }
    loadOrders() {

        const headers = {
            'Authorization': 'Bearer ' + this.state.token,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };

        // NOTE Post to HTTPS only in production
        axios.get(Helper.url+Helper.suburl+'/customer/open?page='+this.state.page, {
            headers: headers
        }).then((response) => {

            if(response.data.data.length == 0) {
                alert("Keine weiteren Kunden gefunden");
            }
            response.data.data.map((label) =>{
                    var diff = Math.abs(new Date() - new Date(label.test.updated_at));
                    label.test.min = Math.floor((diff/1000)/60);
                    this.state.results.push(label)
                }
            );
            setInterval(function() {
                this.updateOrders();
            }.bind(this), 60 * 1000);
            this.setState({results: this.state.results})
        }).catch((errormsg) => {
        });

    }

    updateDimensions() {
        this.setState({ innerwidth: window.innerWidth});
    }

    componentDidMount() {

        this.handleConnectionChange();
        window.addEventListener('online', this.handleConnectionChange);
        window.addEventListener('offline', this.handleConnectionChange);
        window.addEventListener("resize", this.updateDimensions.bind(this));

        this.loadOrders();
    }

    loadMore(){
        this.setState({page: this.state.page+1}, function() {this.loadOrders()});
    }
    // The render method contains the JSX code which will be compiled to HTML.
    render() {
        const { error, isLoaded} = this.state;
        const { classes } = this.props;

        const col = (this.state.innerwidth > 900) ? 8 : 2.5;

        if (isLoaded) {
            return <div style={{textAlign: "center", marginTop: "50%"}}>
                <CircularProgress disableShrink />
            </div>
        } else {

            const flexContainer = {

                padding: 0,
            };

            return (
                <div className={"content"}>
                    <Typography variant="h1" component="h3" gutterBottom>
                        offene Kunden
                    </Typography>


                    <List aria-label="secondary mailbox folders" style={{textAlign: 'left'}}>
                        {this.state.results.map((label, key) =>
                            <ListItemLink onClick={(event) => window.location='/que/' + label.test.test_id}>
                                <ListItemText style={{fontSize: 14}}>
                                    {key}: {label.profile.firstname} {label.profile.lastname} {label.profile.telephone} {label.email}, {label.profile.street} {label.profile.zip} {label.profile.city} (seit {label.test.min} min)
                                </ListItemText>
                            </ListItemLink>
                        )}

                    </List>
                    <Box style={{paddingBottom: 40}}>
                        <Button onClick={() => this.loadMore()} full color={"primary"} style={{width: '300px', backgroundColor: Helper.secondary, color: '#fff'}}>
                            Weitere Anzeigen
                        </Button>

                    </Box>
                </div>
            )
        }

    }
}

export default withStyles(useStyles)(Opencustomer);
