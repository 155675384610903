import React, { Component } from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import MuiAlert from '@material-ui/lab/Alert';
import axios from "axios";
import CircularProgress from '@material-ui/core/CircularProgress';
import successlogo from '../public/tenor.gif'
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import { Calendar, utils } from "react-modern-calendar-datepicker";
import Select from "@material-ui/core/Select";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import Modal from '@material-ui/core/Modal';
import {
    GoogleReCaptchaProvider,
    GoogleReCaptcha
} from 'react-google-recaptcha-v3';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Helper from "../components/helper/helper";

function Alert(props) {
    return <MuiAlert elevation={6} variant="outlined" {...props} />;
}


const useStyles = theme => ({
    boxViewFull: {
        width: '32%',
        marginRight: '1%',
        marginBottom: 20
    },
    inputField: {
        width: '100%',
        borderBottom: 0,
        backgroundColor: "#f9f9f9"
    },
});
const classes = useStyles;

class Booking extends Component {
    constructor(props) {
        super(props);

        const time = moment().format("HH");
        var ex = 0;
        if(this.props.location.search.indexOf("person")) {
            ex = this.props.location.search.replace("?person=",'');
            ex = ex.replace("?type=now&person=",'');
        }

        this.state = {
            simulater: false,
            innerwidth: window.innerWidth,
            isLoading: true,
            error: false,
            coronaapp: null,
            isDisconnected: false,
            counter_users: ex,
            step: 0,
            skipped: new Set(),
            activeStep: window.location.href.indexOf('=now') != -1 ? 1 : 0,
            allowDates: null,
            selectedDay: null,
            captcha_public: "",
            inactiveDates:
                {
                    '0904': [
                        "10:00",
                        "10:10",
                        "11:10",
                    ]
                }
            ,
            currentDate: '12.04',
            currentHour: '11:05',
            interval: 5,
            currentWeek: moment().week()-1,
            addweek: 0,
            realycurrentWeek: moment().week()-1,
            selected_day: moment().format("Y-MM-DD"),
            selected_time: window.location.href.indexOf('=now') != -1 ? moment().hours()+':'+moment().minutes() : null,
            daylist: null,
            showuser: null,
            openHours: parseInt(time) >= 18 || parseInt(time) < 8 ? [8,9] : [parseInt(time), parseInt(time) + 1],
            userlist: localStorage.getItem('users') == null || localStorage.getItem('users') == 'null' ? [] : JSON.parse(localStorage.getItem('users')),
            phone: '',
            email: '',
            email_repeat: '',
            firstname: '',
            pin: '',
            lastname: '',
            bday: '',
            street: '',
            zip: '',
            city: '',
            user_id: '',
            save_data: false,
            showError: false,
            errorText: '',
            openModal: false,
            qrlink: ''
        };



        this.goBack = this.goBack.bind(this);
        this.addDate = this.addDate.bind(this);
        this.showLine = this.showLine.bind(this);
        this.showRow = this.showRow.bind(this);
        this.setSelectedDay = this.setSelectedDay.bind(this);
        this.openUser = this.openUser.bind(this);
    }

    calcAllowDates() {

        var monday = [];
        var tuesday = [];
        var wednesday = [];
        var thursday = [];
        var friday = [];
        var saturday = [];
        var sunday = [];

        var x = this.state.interval; //minutes interval
        var tt = 0; // start time
        const time = moment().format("HH");
        const minute = moment().minute();

        for (var i=0;tt<24*60; i++) {
            var hh = Math.floor(tt/60); // getting hours of day in 0-24 format
            var mm = (tt%60); // getting minutes of the hour in 0-55 format


                //if(moment(this.state.selected_day).format('dddd') == "Friday" || moment(this.state.selected_day).format('dddd') == "Sunday" || moment(this.state.selected_day).format('dddd') == "Saturday") {
                    if((hh > Helper.booking_times.from && hh < Helper.booking_times.to) || (hh > Helper.booking_times.from1 && hh < Helper.booking_times.to1)) {

                        if(this.state.selected_day == moment().format("DDMM") || this.state.selected_day == moment().format("Y-MM-DD")) {
                            if(hh > time) {
                                monday[i] = (hh < 10 ? '0' + hh : hh)  + ':' + ("0" + mm).slice(-2); // pushing data in array in [00:00 - 12:00 AM/PM format]
                            }else{
                                console.log(hh +'=='+ time +'&&'+ minute +'>'+ mm);
                                if(hh == time && mm > minute) {
                                    monday[i] = (hh < 10 ? '0' + hh : hh)  + ':' + ("0" + mm).slice(-2); // pushing data in array in [00:00 - 12:00 AM/PM format]
                                }
                            }
                        }else {
                            monday[i] = (hh < 10 ? '0' + hh : hh)  + ':' + ("0" + mm).slice(-2); // pushing data in array in [00:00 - 12:00 AM/PM format]
                        }

                        tuesday[i] = (hh < 10 ? '0' + hh : hh) + ':' + ("0" + mm).slice(-2); // pushing data in array in [00:00 - 12:00 AM/PM format]

                        wednesday[i] = (hh < 10 ? '0' + hh : hh) + ':' + ("0" + mm).slice(-2); // pushing data in array in [00:00 - 12:00 AM/PM format]

                        thursday[i] = (hh < 10 ? '0' + hh : hh) + ':' + ("0" + mm).slice(-2); // pushing data in array in [00:00 - 12:00 AM/PM format]

                        friday[i] = (hh < 10 ? '0' + hh : hh) + ':' + ("0" + mm).slice(-2); // pushing data in array in [00:00 - 12:00 AM/PM format]

                        saturday[i] = (hh < 10 ? '0' + hh : hh) + ':' + ("0" + mm).slice(-2); // pushing data in array in [00:00 - 12:00 AM/PM format]

                        sunday[i] = (hh < 10 ? '0' + hh : hh) + ':' + ("0" + mm).slice(-2); // pushing data in array in [00:00 - 12:00 AM/PM format]
                    }
               // }



            tt = tt + x;
        }

        const list = [{
                0: monday
            }
        ]

        this.setState({allowDates: list})
    }

    goBack(){
        this.props.history.goBack();
    }

    componentWillUnmount() {
        window.removeEventListener('online', this.handleConnectionChange);
        window.removeEventListener('offline', this.handleConnectionChange);
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    handleConnectionChange = () => {
        const condition = navigator.onLine ? 'online' : 'offline';

        if (condition === 'online') {

        }else{
            this.setState({ isDisconnected: true });
        }
    }

    updateDimensions() {
        this.setState({ innerwidth: window.innerWidth});
    }

    searchByDate() {
        const daylist = [];

        this.setState({simulater: true});

        const inter = this;
        setTimeout(
            function() {
                inter.setState({simulater: false});
            }, 1300);

        const headers = {
            //'Authorization': 'Bearer ' + token,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };

        // NOTE Post to HTTPS only in production
        axios.get(Helper.url+Helper.suburl+'/booking/blockedday/'+this.state.selected_day+'?personen='+this.state.counter_users, {
            headers: headers
        }).then((response) => {

            console.log(response)
            for (var i = 0; i <= 1; i++) {
                if(this.state.addweek < 0) {
                    //daylist.push(moment().subtract(this.state.addweek, 'weeks').add(i, 'days').format("DD.MM"));
                }else{
                    //daylist.push(moment().add(this.state.addweek, 'weeks').add(i, 'days').format("DD.MM"));
                }

            }
            const ddd = this.state.selected_day.split("-")
            daylist.push(ddd[2]+'.'+ddd[1]);

            this.setState({captcha_public: response.data.config.captcha_public, interval: response.data.interval, inactiveDates: response.data.dates, isLoading: false, daylist: daylist}, function() {
                this.calcAllowDates();
            })
        }).catch((errormsg) => {
        });


    }

    search() {
        const daylist = [];



        const headers = {
            //'Authorization': 'Bearer ' + token,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };

        // NOTE Post to HTTPS only in production
        axios.get(Helper.url+Helper.suburl+'/booking/blocked/'+this.state.currentWeek+'?personen='+this.state.counter_users, {
            headers: headers
        }).then((response) => {


            for (var i = 0; i <= 1; i++) {
                if(this.state.addweek < 0) {
                    daylist.push(moment().subtract(this.state.addweek, 'weeks').add(i, 'days').format("DD.MM"));
                }else{
                    daylist.push(moment().add(this.state.addweek, 'weeks').add(i, 'days').format("DD.MM"));
                }

            }

            this.setState({inactiveDates: response.data.dates, isLoading: false, daylist: daylist})
        }).catch((errormsg) => {
        });

        this.calcAllowDates();
    }

    componentDidMount() {

        this.handleConnectionChange();
        window.addEventListener('online', this.handleConnectionChange);
        window.addEventListener('offline', this.handleConnectionChange);
        window.addEventListener("resize", this.updateDimensions.bind(this));

       this.searchByDate();
    }

    getSteps() {
        return ['Datum & Uhrzeit wählen', 'Anmelden & Bestätigung'];
    }

    addDate(index1, content1) {
        console.log(this.state.daylist);
        this.setState({selected_time: content1});
    }

    showRow(content, index) {
        const data = [];
            data.push(<Box style={{width: 35, float: 'left', paddingTop: 12}}></Box>);
            Object.values(content).map((content1, index1) => {
                data.push(<div className={"timbutto"} style={{float:'left'}}>{this.showLine(content1, index1)}</div>);
            });
            data.push(<Box style={{width: 35, float: 'left', paddingTop: 12}}></Box>);
        return data;
    }

    showLine(content, index) {
        const data = [];
        const {selected_day, daylist, openHours} = this.state;

        content.map((content1, index1) => {
            var ex = content1.split(':');
            if(content1 == "17:00") {
                data.push(<Box style={{width: '100%', display: 'block', marginBottom: 20, clear: 'both'}}></Box>);
            }
            if(typeof this.state.inactiveDates[daylist[index].toString().replace('.','')] == "undefined" || this.state.inactiveDates[daylist[index].toString().replace('.','')].indexOf(content1) == -1) {
                data.push(<Box style={{width: 75, margin: 3, float: 'left'}}><Button color={ (this.state.selected_time == content1) ? "primary": "secondary"} variant={"contained"} onClick={() => this.addDate(index, content1)}>{content1}</Button></Box>);
            }else {
                data.push(<Box style={{width: 75, margin: 3, float: 'left'}}><Button color={  (this.state.selected_time == content1) ? "primary": "default"} variant={"contained"}>--:--</Button></Box>);
            }
        })
        //, display: parseInt(ex[0]) >= openHours[0] && parseInt(ex[0]) <= openHours[1] ? 'block' : 'none'
        return data;
    }

    showDates() {
        const {allowDates} = this.state;

        var data = [];
        allowDates.map((content, index) => {
            data.push(this.showRow(allowDates[index]));
        });

        return data;
    }

    showDays() {
        var days = [];
        require('moment/locale/de');

        for (var i = 0; i <= 1; i++) {
            days.push(<Box style={{width: 75, margin: 7, float: 'left'}}>{moment().week(this.state.currentWeek).add(i, 'days').format("dddd DD.MM")}</Box>);
        }

        return days;
    }

    changeWeek(type) {
        const {realycurrentWeek, currentWeek, addweek} = this.state;

        if(type == "+"){
            this.setState({addweek: addweek+1, currentWeek: currentWeek+1}, function(){this.search();});
        }else if(type == "-"){
            if(realycurrentWeek != currentWeek) {
                this.setState({addweek: addweek-1,currentWeek: currentWeek-1}, function(){this.search();});
            }else {
                this.setState({addweek: 0,currentWeek: realycurrentWeek}, function(){this.search();});
            }
        }


    }

    showMore() {
        const {openHours} = this.state;

        const currenthours = openHours[1];
        const newOpenHours = currenthours+2 >= 18 ? 18 : currenthours+2;

        this.setState({openHours: [openHours[0], newOpenHours]})
    }

    searchUser(value, key) {
        this.setState( {
            [key]: value
        })
        const headers = {
            //'Authorization': 'Bearer ' + token,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };
        axios.get(Helper.url+Helper.suburl+'/customer/search/'+value, {
            headers: headers
        }).then((response) => {
            console.log(response)

            if(typeof response.data.email != "undefined") {

                this.setState({
                    email: typeof response.data.email == "undefined" ? '' : response.data.email,
                    firstname: typeof response.data.profile.firstname == "undefined" ? '' : response.data.profile.firstname,
                    pin: typeof response.data.profile.pin == "undefined" ? '' : response.data.profile.pin,
                    lastname: typeof response.data.profile.lastname == "undefined" ? '' : response.data.profile.lastname,
                    bday: typeof response.data.profile.bday == "undefined" ? '' : response.data.profile.bday,
                    d: typeof response.data.profile.bday == "undefined" ? '' : response.data.profile.bday.split("-")[2],
                    m: typeof response.data.profile.bday == "undefined" ? '' : response.data.profile.bday.split("-")[1],
                    y: typeof response.data.profile.bday == "undefined" ? '' : response.data.profile.bday.split("-")[0],
                    street: typeof response.data.profile.street == "undefined" ? '' : response.data.profile.street,
                    zip: typeof response.data.profile.zip == "undefined" ? '' : response.data.profile.zip,
                    city: typeof response.data.profile.city == "undefined" ? '' : response.data.profile.city,
                    phone: typeof response.data.profile.telephone == "undefined" ? '' : response.data.profile.telephone,
                })
            }
        });
    }

    onChange(value, key) {
        this.setState( {
            [key]: value
        })
    }

    setSelectedDay(date) {
        this.setState({selectedDay: date, selected_day: date['year'] + '-' + (date['month'] < 10 ? '0'+date['month'] : date['month']) + '-' + (date['day'] < 10 ? '0'+date['day'] : date['day'])}, function() {this.searchByDate()})
    }

    openUser(j) {
        console.log(j);
        var i = j - 1;
        this.setState({showuser: i, email: this.state.userlist[i].email,
            firstname: this.state.userlist[i].firstname,
            lastname: this.state.userlist[i].lastname,
            pin: this.state.userlist[i].pin,
            bday: this.state.userlist[i].bday,
            street: this.state.userlist[i].street,
            zip: this.state.userlist[i].zip,
            city: this.state.userlist[i].city,
            phone: this.state.userlist[i].phone,
            save_data: this.state.userlist[i].save_data})
    }

netPers(i) {

	if(this.state.email == "" || this.state.firstname == "" || this.state.lastname == "" || this.state.d == "" || this.state.street == "" || this.state.zip == "" || this.state.city == "") {
                    this.setState({showError: true, errorText: 'Bitte fülle alle Pflichtfelder aus'})
		alert("Bitte fülle alle Pflichtfelder aus")
                    const inner = this;

                    setTimeout(function(){
                        inner.setState({showError: false, errorText: ''})
                    }, 2000);
                    return false;
                }


    if(this.state.email != this.state.email_repeat) {
        this.setState({showError: true, errorText: 'Deine E-Mail Adresse weicht ab'})
        alert('Deine E-Mail Adresse weicht ab'  );
        const inner = this;
        setTimeout(function(){
            inner.setState({showError: false, errorText: ''})
        }, 2000);
        return false;
    }


                this.state.userlist.push({
                        email: this.state.email,
                        email_repeat: this.state.email_repeat,
                        phone: this.state.phone,
                        firstname: this.state.firstname,
                        lastname: this.state.lastname,
                        pin: this.state.pin,
						bday: this.state.y + "-" + this.state.m+"-"+this.state.d,
                        street: this.state.street,
                        zip: this.state.zip,
                        city: this.state.city,
                        save_data: this.state.save_data
                    }
                )


                this.setState({
                    userlist: this.state.userlist,
                    email: '',
                    firstname: '',
                    email_repeat: '',
                    pin: '',
                    phone: '',
                    lastname: '',
                    bday: '',
                    street: '',
                    zip: '',
                    city: '',
                    save_data: false,
                    save_data2: false,
                    save_data1: false,
                    user_id: "",
					d: "",
					m: "",
					y: "",
					showuser: i+1
                }, function() {

                });
}

    getStepContent(step) {
        const {showError, errorText, selectedDay, simulater} = this.state;
        switch (step) {
            case 0:
                const handleChangeCounterUsers = (event) => {
                    this.setState({
                        counter_users: event.target.value,
                    }, function (){ this.searchByDate() });
                };

                const langDE = {
                    // months list by order
                    months: [
                        'Januar',
                        'Februar',
                        'März',
                        'April',
                        'Mai',
                        'Juni',
                        'Juli',
                        'August',
                        'September',
                        'Oktober',
                        'November',
                        'Dezember',
                    ],

                    // week days by order
                    weekDays: [
                        {
                            name: 'Montag',
                            short: 'M',
                        },
                        {
                            name: 'Dienstag',
                            short: 'D',
                        },
                        {
                            name: 'Mittwoch',
                            short: 'M',
                        },
                        {
                            name: 'Donnerstag',
                            short: 'D',
                        },
                        {
                            name: 'Freitag',
                            short: 'F',
                        },
                        {
                            name: 'Samstag',
                            short: 'S',
                            isWeekend: true,
                        },
                        {
                            name: 'Sonntag', // used for accessibility
                            short: 'S', // displayed at the top of days' rows
                            isWeekend: true, // is it a formal weekend or not?
                        },
                    ],

                    // just play around with this number between 0 and 6
                    weekStartingIndex: 6,

                    // return a { year: number, month: number, day: number } object
                    getToday(gregorainTodayObject) {
                        return gregorainTodayObject;
                    },

                    // return a native JavaScript date here
                    toNativeDate(date) {
                        return new Date(date.year, date.month - 1, date.day);
                    },

                    // return a number for date's month length
                    getMonthLength(date) {
                        return new Date(date.year, date.month, 0).getDate();
                    },

                    // return a transformed digit to your locale
                    transformDigit(digit) {
                        return digit;
                    },

                    // texts in the date picker
                    nextMonth: 'nächster Monat',
                    previousMonth: 'voriger Monat',
                    openMonthSelector: 'Monatsauswahl',
                    openYearSelector: 'Jahresauswahl',
                    closeMonthSelector: 'Monatsauswahl schließen',
                    closeYearSelector: 'schließen',
                    defaultPlaceholder: 'Auswählen...',

                    // for input range value
                    from: 'from',
                    to: 'to',


                    // used for input value when multi dates are selected
                    digitSeparator: ',',

                    // if your provide -2 for example, year will be 2 digited
                    yearLetterSkip: 0,

                    // is your language rtl or ltr?
                    isRtl: false,
                }

                return (
                    <div>
                        <div>
                            <p style={{textAlign: 'center'}}>
                                Personenanzahl:
                            </p>
                            <div style={{textAlign: 'center'}}>
                            <Box className={"former-box-select"}>
                                <Select
                                    native
                                    value={this.state.counter_users}
                                    onChange={handleChangeCounterUsers}
                                    inputProps={{
                                        name: 'person',
                                        id: 'age-native-simple',
                                    }}
                                >
                                    <option value={1}>1 Person</option>
                                    <option value={2}>2 Personen</option>
                                    <option value={3}>3 Personen</option>
                                    <option value={4}>4 Personen</option>
                                </Select>
                            </Box>
                            </div>
                            <p style={{textAlign: 'center', marginTop: 30}}>
                                Termin wählen
                            </p>
                        </div>
                        <div style={{float: 'left', width: window.innerWidth < 900 ? '100%' :  '40%'}}>
                            <Calendar
                                minimumDate={utils().getToday()}
                                value={selectedDay}
                                onChange={this.setSelectedDay}
                                shouldHighlightWeekends
                                locale={langDE}
                                customDaysClassName={[
                                    // here we add some CSS classes
                                    //{ year: 2021, month: 4, day: 13, className: 'purpleDay' },
                                    //{ year: 2021, month: 4, day: 14, className: 'orangeDay' },
                                    //{ year: 2021, month: 4, day: 18, className: 'yellowDay' },
                                    //{ year: 2021, month: 4, day: 26, className: 'navyBlueDay' },
                                ]}
                            />
                        </div>
                        <div style={{float: 'left', width: window.innerWidth < 900 ? '100%' : '60%'}}>
                            {
                                simulater ?
                                    <div style={{textAlign: 'center', marginTop: 20}}><CircularProgress disableShrink /></div>
                                    :
                                    this.showDates()
                            }


                        </div>
                    </div>
                )
                break;
            case -10:
                return (
                    <Box style={{overflow: this.state.valueOf() < 900 ? 'scroll' : "hidden"}}>
                        <Box style={{textAlign: 'center', fontSize: 14}}>
                            <Box style={{width: 35, float: 'left', paddingTop: 12}} onClick={() => this.changeWeek('-')}>
                                &lang;
                            </Box>
                            {this.showDays()}
                            <Box style={{width: 35, float: 'left', paddingTop: 12}} onClick={() => this.changeWeek('+')}>
                                &rang;
                            </Box>
                        </Box>
                        <Box style={{clear: 'both'}}></Box>
                        <Box className={classes.flex} style={{flexDirection: "row"}}>

                            {this.showDates()}
                        </Box>
                        <Box style={{clear: 'both'}}></Box>
                        <Box style={{paddingLeft: 40}}>
                            <Button onClick={() => this.showMore()} full color={"primary"} style={{width: '300px', backgroundColor: Helper.secondary, color: '#fff'}}>
                                Weitere Anzeigen
                            </Button>
                        </Box>
                        {
                            showError ?
                                <Alert severity="error">{errorText}</Alert>
                                :
                                null
                        }
                    </Box>)
                    ;
            case 1:
                const {selected_day, selected_time} = this.state;
                const handleChange = (event) => {
                    this.setState({save_data: event.target.checked});
                };

                const handleChange1 = (event) => {
                    this.setState({save_data1: event.target.checked});
                };

                const handleChange4 = (event) => {

                    this.setState({coronaapp: event.target.value});
                };

                const handleChange2 = (event) => {
                    this.setState({save_data2: event.target.checked});
                };

                const handleOpen = () => {
                    this.setState({openModal: true});
                };

                const handleModalClose = () => {
                    this.setState({openModal: false});
                };

                const daten = [];
                const data = [];
                var k = 0;
                for (var i = 0; i < this.state.counter_users; i++) {
                    data.push(
                        <Box style={{backgroundColor: '#f0f0f0', marginBottom: 10, padding: 10}}>
                            {(this.state.userlist.length > 0 && typeof this.state.userlist[i] != "undefined") ?
                                <Box  onClick={() => this.openUser(k+1)}>
                                    {this.state.userlist[i].firstname + ' ' + this.state.userlist[i].lastname}
                                    <Box style={{float: 'right'}}>
                                        <IconButton color="primary" component="span" size="small" style={{transform: [{ rotate: '-90deg'}]}}>
                                            &rsaquo;
                                        </IconButton>
                                    </Box>
                                    <Box style={{clear: 'both'}}></Box>
                                </Box>
                                :
                                null
                            }

                            <Box style={{display: this.state.showuser == i || this.state.userlist.length == i ? 'block' : 'none'}}>
                                <Box className={"boxViewFull"} style={{width: window.innerWidth < 800 ? "100%": "42%"}}>
                                    <small style={{fontSize: 12}}>Du hast bereits ein Konto? Du findest deine Kunden ID auf deinem QR-Codee z.B. 00~Mustermann</small>
                                    <div style={{display: window.innerWidth < 800 ? 'block' :"flex"}}>
                                        <TextField id="filled-basic" fullWidth label="Dein Kunden ID aus dem QR-Code" variant="outlined" className={classes.inputField} value={this.state.user_id}
                                                   onChange={(e) => {this.onChange(e.target.value, 'user_id')}}/>

                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => this.searchUser(this.state.user_id,  i)}
                                            style={{width: window.innerWidth < 800 ? "100%": "auto"}}
                                        >
                                            Suchen
                                        </Button>
                                    </div>
                                </Box>
                                <Box style={{clear: 'both'}}></Box>


                                <Box className={"boxViewFull"}>
                                    <TextField id="filled-basic" fullWidth label="Vorname" variant="outlined" className={classes.inputField} value={this.state.firstname}
                                               onChange={(e) => {this.onChange(e.target.value, 'firstname')}}/>
                                </Box>

                                <Box className={"boxViewFull"}>
                                    <TextField id="filled-basic" fullWidth label="Nachname" variant="outlined" className={classes.inputField} value={this.state.lastname}
                                               onChange={(e) => {this.onChange(e.target.value, 'lastname')}}/>
                                </Box>


                                <Box className={"boxViewFull"}>
                                    <div style={{display: "flex"}}>
                                    <TextField id="filled-basic" type={'text'} style={{width:"28%"}} helperText={"Geburtsdatum"} fullWidth label="Tag" variant="outlined" className={classes.inputField} value={this.state.d}
                                               onChange={(e) => {this.onChange(e.target.value > 1 ? e.target.value.substr(0,2) : e.target.value, 'd')}}/>
                                    .
                                    <TextField id="filled-basic" type={'text'} style={{width:"28%"}} fullWidth label="Monat" variant="outlined" className={classes.inputField} value={this.state.m}
                                               onChange={(e) => {this.onChange(e.target.value > 1 ? e.target.value.substr(0,2) : e.target.value, 'm')}}/>
                                    .
                                    <TextField id="filled-basic" type={'text'} style={{width:"44%"}}x fullWidth label="Jahr" variant="outlined" className={classes.inputField} value={this.state.y}
                                               onChange={(e) => {this.onChange(e.target.value > 3 ? e.target.value.substr(0,4) : e.target.value, 'y')}}/>
                                    </div>
                                </Box>
                                <Box style={{clear: 'both'}}></Box>
                                <Box className={"boxViewFull"}>
                                    <TextField id="filled-basic" fullWidth label="Straße & Hausnummer" variant="outlined" className={classes.inputField} value={this.state.street}
                                               onChange={(e) => {this.onChange(e.target.value, 'street')}}/>
                                </Box>

                                <Box className={"boxViewFull"}>
                                        <TextField id="filled-basic" type={"number"} fullWidth label="PLZ" variant="outlined" className={classes.inputField} value={this.state.zip}
                                               onChange={(e) => {this.onChange(e.target.value > 4 ? e.target.value.substr(0,5) : e.target.value, 'zip')}}/>
                                </Box>

                                <Box className={"boxViewFull"}>
                                    <TextField id="filled-basic" fullWidth label="Ort" variant="outlined" className={classes.inputField} value={this.state.city}
                                               onChange={(e) => {this.onChange(e.target.value, 'city')}}/>
                                </Box>
                                <Box style={{clear: 'both'}}></Box>
                                <Box className={"boxViewFull"}>
                                    <TextField id="filled-basic" type={"email"} fullWidth label="E-Mail" variant="outlined" className={classes.inputField} value={this.state.email}
                                               onChange={(e) => {this.onChange(e.target.value, 'email')}}/>
                                </Box>

                                <Box className={"boxViewFull"}>
                                    <TextField id="filled-basic" type={"email"} fullWidth label="E-Mail wiederholen" variant="outlined" className={classes.inputField} value={this.state.email_repeat}
                                               onChange={(e) => {this.onChange(e.target.value, 'email_repeat')}}/>
                                </Box>
                                <Box style={{clear: 'both'}}></Box>
                                <Box className={"boxViewFull"}>
                                    <TextField id="filled-basic" type={"phone"} fullWidth label="Telefon" variant="outlined" className={classes.inputField} value={this.state.phone}
                                               onChange={(e) => {this.onChange(e.target.value, 'phone')}}/>
                                </Box>
                                <Box className={"boxViewFull"}>
                                    <TextField id="filled-basic" fullWidth label="Personalausweisnummer (optional)" variant="outlined" className={classes.inputField} value={this.state.pin}
                                               onChange={(e) => {this.onChange(e.target.value, 'pin')}}/>
                                </Box>
                                <Box style={{clear: 'both'}}></Box>
                                <Box className={"boxViewFull"} style={{width: '100%'}}>
                                    <h4 style={{margin: 0, padding: 0}}>Tipp: </h4>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.save_data}
                                                onChange={handleChange}
                                                name="checkedB"
                                                color="primary"
                                            />
                                        }
                                        label="Meine Daten speichern um bei der nächsten Buchung Zeit zu sparen"
                                    />

                                </Box>

								{this.state.counter_users == 1 ? null :  <Box style={{float: 'right'}}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => this.netPers(i)}

                                    >
                                        Weiter
                                    </Button>
                                </Box>}


                                <Box style={{clear: 'both'}}></Box>

                            </Box>
                        </Box>
                    );
                }


                return (
                    <Box>
                        <Box>
                            Person {parseInt(this.state.userlist.length) == 0 ? '1' : parseInt(this.state.userlist.length) + 1} von {this.state.counter_users}
                        </Box>

                        {
                            data
                        }

                        {
                        this.state.counter_users > 1 && this.state.counter_users != parseInt(this.state.userlist.length) ? null :
                        <Box>
                            <Box style={{ marginBottom: 18, fontWeight: 'bold', color: "red"}}>Bitte überprüfe deinen Spam-Ordner, solltest du die Email zur Bestätigung nicht erhalten.</Box>
                            <Box>Dein gewähltes Datum: <b style={{fontWeight: 'bold'}}>{selected_day.split('-')[2] + '.' + selected_day.split('-')[1] + '.' + selected_day.split('-')[0]}</b></Box>
                            <Box>Deine gewählte Uhrzeit: <b style={{fontWeight: 'bold'}}>{selected_time} Uhr</b></Box>
                            <Box style={{fontWeight: 'bold', marginTop: 20}}>Bitte überprüfe die Richtigkeit deiner Daten.</Box>
                            <Box style={{marginTop: 20}}>Personen:</Box>
                            <Box>
                                {daten}
                            </Box>

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.save_data1}
                                        onChange={handleChange1}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label="Ich habe die Datenschutzerklärung gelesen, verstanden und akzeptiert."
                            />
                            <div>
                                <Button color={"primary"} onClick={() => handleOpen()}>
                                    Datenschutzerklärung öffnen
                                </Button>
                            </div>

                            <Modal
                                open={this.state.openModal}
                                onClose={handleModalClose}
                                aria-labelledby="simple-modal-title"
                                aria-describedby="simple-modal-description"
                            >
                                <div style={{position: 'absolute',
                                    width: '70%',
                                    backgroundColor: "#fff",
                                    border: '0px solid #000',
                                    overflow: 'scroll',
                                    height: '70%',
                                    marginLeft: '15%',
                                    marginTop: '3%',
                                    padding: 30}}>

                                    <Button color={"primary"} onClick={() => handleModalClose()}>
                                        Datenschutzerklärung schließen
                                    </Button>

                                    <h3>Datenschutzerklärung</h3>

                                    Wir freuen uns über Ihr Interesse an unserem Online-System. Der Schutz Ihrer Privatsphäre ist für uns sehr wichtig. Nachstehend informieren wir Sie ausführlich über den Umgang mit Ihren Daten.

                                    <h3>1. Zugriffsdaten und Hosting</h3>

                                    Sie können unsere Webseiten besuchen, ohne Angaben zu Ihrer Person zu machen. Bei jedem Aufruf einer Webseite speichert der Webserver lediglich automatisch ein sogenanntes Server-Logfile, das z.B. den Namen der angeforderten Datei, Ihre IP-Adresse, Datum und Uhrzeit des Abrufs, übertragene Datenmenge und den anfragenden Provider (Zugriffsdaten) enthält und den Abruf dokumentiert.
                                    Diese Zugriffsdaten werden ausschließlich zum Zwecke der Sicherstellung eines störungsfreien Betriebs der Seite sowie der Verbesserung unseres Angebots ausgewertet. Dies dient gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO der Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden berechtigten Interessen an einer korrekten Darstellung unseres Angebots. Alle Zugriffsdaten werden spätestens sieben Tage nach Ende Ihres Seitenbesuchs gelöscht.
                                    <br/>
                                    Hostingdienstleistungen durch einen Drittanbieter
                                    Im Rahmen einer Verarbeitung in unserem Auftrag erbringt ein Drittanbieter für uns die Dienste zum Hosting und zur Darstellung der Webseite. Dies dient der Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden berechtigten Interessen an einer korrekten Darstellung unseres Angebots. Alle Daten, die im Rahmen der Nutzung dieser Webseite oder in dafür vorgesehenen Formularen im Onlineshop wie folgend beschrieben erhoben werden, werden auf seinen Servern verarbeitet. Eine Verarbeitung auf anderen Servern findet nur in dem hier erläuterten Rahmen statt.
                                    Dieser Dienstleister sitzt innerhalb eines Landes der Europäischen Union oder des Europäischen Wirtschaftsraums.

                                    <h3>
                                        2. Datenerhebung und -verwendung zur Vertragsabwicklung und bei Eröffnung eines Kundenkontos
                                    </h3>
                                    Wir erheben personenbezogene Daten, wenn Sie uns diese im Rahmen Ihrer Bestellung, bei einer Kontaktaufnahme mit uns (z.B. per Kontaktformular oder E-Mail) oder bei Eröffnung eines Kundenkontos freiwillig mitteilen. Pflichtfelder werden als solche gekennzeichnet, da wir in diesen Fällen die Daten zwingend zur Vertragsabwicklung, bzw. zur Bearbeitung Ihrer Kontaktaufnahme oder Eröffnung des Kundenkontos benötigen und Sie ohne deren Angabe die Bestellung und/ oder die Kontoeröffnung nicht abschließen, bzw. die Kontaktaufnahme nicht versenden können. Welche Daten erhoben werden, ist aus den jeweiligen Eingabeformularen ersichtlich. Wir verwenden die von ihnen mitgeteilten Daten gemäß Art. 6 Abs. 1 S. 1 lit. b DSGVO zur Vertragsabwicklung und Bearbeitung Ihrer Anfragen. Nach vollständiger Abwicklung des Vertrages oder Löschung Ihres Kundenkontos werden Ihre Daten für die weitere Verarbeitung eingeschränkt und nach Ablauf der steuer- und handelsrechtlichen Aufbewahrungsfristen gelöscht, sofern Sie nicht ausdrücklich in eine weitere Nutzung Ihrer Daten eingewilligt haben oder wir uns eine darüber hinausgehende Datenverwendung vorbehalten, die gesetzlich erlaubt ist und über die wir Sie in dieser Erklärung informieren. Die Löschung Ihres Kundenkontos ist jederzeit möglich und kann entweder durch eine Nachricht an die unten beschriebene Kontaktmöglichkeit oder über eine dafür vorgesehene Funktion im Kundenkonto erfolgen.

                                    <h3>3. Datenweitergabe</h3>
                                    Zur Vertragserfüllung gemäß Art. 6 Abs. 1 S. 1 lit. b DSGVO geben wir Ihre Daten an das mit der Lieferung beauftragte Versandunternehmen weiter, soweit dies zur Lieferung bestellter Waren erforderlich ist. Je nach dem, welchen Zahlungsdienstleister Sie im Bestellprozess auswählen, geben wir zur Abwicklung von Zahlungen die hierfür erhobenen Zahlungsdaten an das mit der Zahlung beauftragte Kreditinstitut und ggf. von uns beauftragte Zahlungsdienstleister weiter bzw. an den ausgewählten Zahlungsdienst. Zum Teil erheben die ausgewählten Zahlungsdienstleister diese Daten auch selbst, soweit Sie dort ein Konto anlegen. In diesem Fall müssen Sie sich im Bestellprozess mit Ihren Zugangsdaten bei dem Zahlungsdienstleister anmelden. Es gilt insoweit die Datenschutzerklärung des jeweiligen Zahlungsdienstleisters.

                                    <h3>4. E-Mail-Newsletter und Postwerbung</h3>
                                    E-Mail-Werbung mit Anmeldung zum Newsletter
                                    Wenn Sie sich zu unserem Newsletter anmelden, verwenden wir die hierfür erforderlichen oder gesondert von Ihnen mitgeteilten Daten, um Ihnen regelmäßig unseren E-Mail-Newsletter aufgrund Ihrer Einwilligung gemäß Art. 6 Abs. 1 S. 1 lit. a DSGVO zuzusenden.
                                    Die Abmeldung vom Newsletter ist jederzeit möglich und kann entweder durch eine Nachricht an die unten beschriebene Kontaktmöglichkeit oder über einen dafür vorgesehenen Link im Newsletter erfolgen. Nach Abmeldung löschen wir Ihre E-Mail-Adresse, soweit Sie nicht ausdrücklich in eine weitere Nutzung Ihrer Daten eingewilligt haben oder wir uns eine darüber hinausgehende Datenverwendung vorbehalten, die gesetzlich erlaubt ist und über die wir Sie in dieser Erklärung informieren.
                                    Der Newsletter wird im Rahmen einer Verarbeitung in unserem Auftrag durch einen Dienstleister versendet, an den wir Ihre E-Mail-Adresse hierzu weitergeben.
                                    Dieser Dienstleister sitzt innerhalb eines Landes der Europäischen Union oder des Europäischen Wirtschaftsraums.
                                    <br/><br/>
                                    Postwerbung und Ihr Widerspruchsrecht
                                    Darüber hinaus behalten wir uns vor, Ihren Vor- und Nachnamen sowie Ihre Postanschrift für eigene Werbezwecke zu nutzen, z.B. zur Zusendung von interessanten Angeboten und Informationen zu unseren Produkten per Briefpost. Dies dient der Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden berechtigten Interessen an einer werblichen Ansprache unserer Kunden gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO.
                                    <h3>5. Cookies und Webanalyse</h3>
                                    Um den Besuch unserer Website attraktiv zu gestalten und die Nutzung bestimmter Funktionen zu ermöglichen, um passende Produkte anzuzeigen oder zur Marktforschung verwenden wir auf verschiedenen Seiten sogenannte Cookies. Dies dient der Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden berechtigten Interessen an einer optimierten Darstellung unseres Angebots gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO. Cookies sind kleine Textdateien, die automatisch auf Ihrem Endgerät gespeichert werden. Einige der von uns verwendeten Cookies werden nach Ende der Browser-Sitzung, also nach Schließen Ihres Browsers, wieder gelöscht (sog. Sitzungs-Cookies). Andere Cookies verbleiben auf Ihrem Endgerät und ermöglichen uns, Ihren Browser beim nächsten Besuch wiederzuerkennen (persistente Cookies). Die Dauer der Speicherung können Sie der Übersicht in den Cookie-Einstellungen Ihres Webbrowsers entnehmen. Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und einzeln über deren Annahme entscheiden oder die Annahme von Cookies für bestimmte Fälle oder generell ausschließen. Jeder Browser unterscheidet sich in der Art, wie er die Cookie-Einstellungen verwaltet. Diese ist in dem Hilfemenü jedes Browsers beschrieben, welches Ihnen erläutert, wie Sie Ihre Cookie-Einstellungen ändern können. Diese finden Sie für die jeweiligen Browser unter den folgenden Links:
                                    Internet Explorer™: https://windows.microsoft.com/de-DE/windows-vista/Block-or-allow-cookies
                                    Safari™: https://support.apple.com/kb/ph21411?locale=de_DE
                                    Chrome™: https://support.google.com/chrome/bin/answer.py?hl=de&hlrm=en&answer=95647
                                    Firefox™ https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen
                                    Opera™ : https://help.opera.com/Windows/10.20/de/cookies.html
                                    Bei der Nichtannahme von Cookies kann die Funktionalität unserer Website eingeschränkt sein.
                                    <br/><br/>
                                    Einsatz von Google (Universal) Analytics zur Webanalyse<br/>
                                    Zur Webseitenanalyse setzt diese Website Google (Universal) Analytics ein, einen Webanalysedienst der Google LLC (www.google.de). Dies dient der Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden berechtigten Interessen an einer optimierten Darstellung unseres Angebots gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO. Google (Universal) Analytics verwendet Methoden, die eine Analyse der Benutzung der Website durch Sie ermöglichen, wie zum Beispiel Cookies. Die automatisch erhobenen Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Durch die Aktivierung der IP-Anonymisierung auf dieser Webseite wird dabei die IP-Adresse vor der Übermittlung innerhalb der Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte anonymisierte IP-Adresse wird grundsätzlich nicht mit anderen Daten von Google zusammengeführt. Nach Zweckfortfall und Ende des Einsatzes von Google Analytics durch uns werden die in diesem Zusammenhang erhobenen Daten gelöscht.
                                    Die Google LLC hat ihren Hauptsitz in den USA und ist zertifiziert unter dem EU-US-Privacy Shield. Ein aktuelles Zertifikat kann hier eingesehen werden. Aufgrund dieses Abkommens zwischen den USA und der Europäischen Kommission hat letztere für unter dem Privacy Shield zertifizierte Unternehmen ein angemessenes Datenschutzniveau festgestellt.
                                    Sie können die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren: https://tools.google.com/dlpage/gaoptout?hl=de
                                    Alternativ zum Browser-Plugin können Sie diesen Link klicken, um die Erfassung durch Google Analytics auf dieser Website zukünftig zu verhindern. Dabei wird ein Opt-Out-Cookie auf Ihrem Endgerät abgelegt. Löschen Sie Ihre Cookies, müssen Sie den Link erneut klicken.

                                    <h3>6. Kontaktmöglichkeiten und Ihre Rechte</h3>
                                    Als Betroffener haben Sie folgende Rechte:
                                    •	gemäß Art. 15 DSGVO das Recht, in dem dort bezeichneten Umfang Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen;
                                    •	gemäß Art. 16 DSGVO das Recht, unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen;
                                    •	gemäß Art. 17 DSGVO das Recht, die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen, soweit nicht die weitere Verarbeitung
                                    – zur Ausübung des Rechts auf freie Meinungsäußerung und Information;
                                    – zur Erfüllung einer rechtlichen Verpflichtung;
                                    – aus Gründen des öffentlichen Interesses oder
                                    – zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
                                    erforderlich ist;
                                    •	gemäß Art. 18 DSGVO das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, soweit
                                    – die Richtigkeit der Daten von Ihnen bestritten wird;
                                    – die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung ablehnen;
                                    – wir die Daten nicht mehr benötigen, Sie diese jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen oder
                                    – Sie gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben;
                                    •	gemäß Art. 20 DSGVO das Recht, Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesebaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen;
                                    •	gemäß Art. 77 DSGVO das Recht, sich bei einer Aufsichtsbehörde zu beschweren. In der Regel können Sie sich hierfür an die Aufsichtsbehörde Ihres üblichen Aufenthaltsortes oder Arbeitsplatzes oder unseres Unternehmenssitzes wenden.
                                    Bei Fragen zur Erhebung, Verarbeitung oder Nutzung Ihrer personenbezogenen Daten, bei Auskünften, Berichtigung, Sperrung oder Löschung von Daten sowie Widerruf erteilter Einwilligungen oder Widerspruch gegen eine bestimmte Datenverwendung wenden Sie sich bitte direkt an uns über die Kontaktdaten in unserem Impressum.

                                    <h3>Widerspruchsrecht</h3>
                                    Soweit wir zur Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden berechtigten Interessen personenbezogene Daten wie oben erläutert verarbeiten, können Sie dieser Verarbeitung mit Wirkung für die Zukunft widersprechen. Erfolgt die Verarbeitung zu Zwecken des Direktmarketings, können Sie dieses Recht jederzeit wie oben beschrieben ausüben. Soweit die Verarbeitung zu anderen Zwecken erfolgt, steht Ihnen ein Widerspruchsrecht nur bei Vorliegen von Gründen, die sich aus Ihrer besonderen Situation ergeben, zu.
                                    Nach Ausübung Ihres Widerspruchsrechts werden wir Ihre personenbezogenen Daten nicht weiter zu diesen Zwecken verarbeiten, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder wenn die Verarbeitung der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen dient.
                                    Dies gilt nicht, wenn die Verarbeitung zu Zwecken des Direktmarketings erfolgt. Dann werden wir Ihre personenbezogenen Daten nicht weiter zu diesem Zweck verarbeiten.

                                </div>
                            </Modal>

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.save_data2}
                                        onChange={handleChange2}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label="Meine Buchungsdaten dürfen an meine angegebene E-Mail-Adresse gesendet werden."
                            />



                            <div>
                                <h3>Möchten Sie das Testergebnis an die Corona-Warn-App übermitteln?</h3>

                                <RadioGroup aria-label="gender" name="gender1" value={this.state.coronaapp} onChange={handleChange4}>
                                    <FormControlLabel value="keine" control={<Radio />} label="KEINE DATEN AN DIE CORONA-WARN-APP ÜBERMITTELN" />
                                    <FormControlLabel value="anonym" control={<Radio />} label="EINWILLIGUNG ZUR PSEUDONYMISIERTEN ÜBERMITTLUNG (NICHT NAMENTLICH ANZEIGE)" />
                                    <p>Hiermit erkläre ich mein Einverständnis zum Übermitteln meines Testergebnisses und meines pseudonymen Coes an das Serversystem des RKI, damit ich mein Testergebnis mit der Corona Warn App abrufen kann. Das Testergebnis in der App kann hierbei nicht als namentlicher Testnachweis verwendet werden. Ich habe die Datenschutzhinweise gelesen.</p>
                                    <FormControlLabel value="full" control={<Radio />} label="EINWILLIGUNG ZUR PERSONALISIERTEN ÜBERMITTLUNG (NAMENTLICHER TESTNACHWEIS)" />
                                    <p>
                                        Hiermit erkläre ich mein Einverständnis zum Übermitteln des Testergebnisses und meines pseudonymen Codes an das Serversystem des RKI, damit ich mein Testergebnis mit der Corona Warn App abrufen kann. Ich willige außerdem in die Übermittlung meines Namens und Geburtsdatum an die App ein, damit mein Testergebnis in der App als namentlicher Testnachweis angezeigt werden kann. Ich habe die Datenschutzhinweise gelesen.
                                    </p>
                                </RadioGroup>
                            </div>

                            {
                                showError ?
                                    <Alert severity="error">{errorText}</Alert>
                                    :
                                    null
                            }
                            </Box>
                            }
                    </Box>)
                    ;
            case 2:
                //const {selected_day, selected_time} = this.state;





                for (var i = 0; i < this.state.counter_users; i++) {
                    daten.push(
                        <Box style={{backgroundColor: '#f0f0f0', marginBottom: 10, padding: 10}}>
                            {this.state.userlist.length > 0 && typeof this.state.userlist[i] != "undefined" ?
                                <Box>
                                    <Box>{this.state.userlist[i].pin}</Box>
                                    <Box>{this.state.userlist[i].firstname + ' ' + this.state.userlist[i].lastname}</Box>
                                    <Box>{this.state.userlist[i].bday}</Box>
                                    <Box>{this.state.userlist[i].street + ', ' + this.state.userlist[i].zip + ' ' + this.state.userlist[i].city}</Box>
                                    <Box>{this.state.userlist[i].email}, {this.state.userlist[i].phone}</Box>

                                </Box>
                                :
                                null
                            }
                        </Box>);
                }
                return <Box style={{marginLeft: 35}}>
                    <Box style={{ marginBottom: 18, fontWeight: 'bold', color: "red"}}>Bitte überprüfe deinen Spam-Ordner, solltest du die Email zur Bestätigung nicht erhalten.</Box>
                    <Box>Dein gewähltes Datum: <b style={{fontWeight: 'bold'}}>{selected_day.split('-')[2] + '.' + selected_day.split('-')[1] + '.' + selected_day.split('-')[0]}</b></Box>
                    <Box>Deine gewählte Uhrzeit: <b style={{fontWeight: 'bold'}}>{selected_time} Uhr</b></Box>
                    <Box style={{fontWeight: 'bold', marginTop: 20}}>Bitte überprüfe die Richtigkeit deiner Daten.</Box>
                    <Box style={{marginTop: 20}}>Personen:</Box>
                    <Box>
                        {daten}
                    </Box>

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.state.save_data1}
                                onChange={handleChange1}
                                name="checkedB"
                                color="primary"
                            />
                        }
                        label="Ich habe die unter Impressum und Datenschutzerklärung aufgeführte Datenschutzerklärung gelesen, verstanden und akzeptiere sie."
                    />
                    <div>
                        <Button color={"primary"} onClick={() => handleOpen()}>
                            Datenschutzerklärung öffnen
                        </Button>
                    </div>

                    <RadioGroup aria-label="gender" name="gender1" value={this.state.coronaapp} onChange={handleChange}>
                        <FormControlLabel value="keine" control={<Radio />} label="KEINE DATEN AN DIE CORONA-WARN-APP ÜBERMITTELN" />
                        <FormControlLabel value="anonym" control={<Radio />} label="EINWILLIGUNG ZUR PSEUDONYMISIERTEN ÜBERMITTLUNG (NICHT NAMENTLICH ANZEIGE)" />
                        <FormControlLabel value="full" control={<Radio />} label="EINWILLIGUNG ZUR PERSONALISIERTEN ÜBERMITTLUNG (NAMENTLICHER TESTNACHWEIS)" />
                    </RadioGroup>

                    <Modal
                        open={this.state.openModal}
                        onClose={handleModalClose}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                    >
                        <div style={{position: 'absolute',
                            width: '70%',
                            backgroundColor: "#fff",
                            border: '0px solid #000',
                            overflow: 'scroll',
                            height: '70%',
                            marginLeft: '15%',
                            marginTop: '3%',
                            padding: 30}}>

                            <Button color={"primary"} onClick={() => handleModalClose()}>
                                Datenschutzerklärung schließen
                            </Button>

                        <h3>Datenschutzerklärung</h3>

                        Wir freuen uns über Ihr Interesse an unserem Online-System. Der Schutz Ihrer Privatsphäre ist für uns sehr wichtig. Nachstehend informieren wir Sie ausführlich über den Umgang mit Ihren Daten.

                        <h3>1. Zugriffsdaten und Hosting</h3>

                        Sie können unsere Webseiten besuchen, ohne Angaben zu Ihrer Person zu machen. Bei jedem Aufruf einer Webseite speichert der Webserver lediglich automatisch ein sogenanntes Server-Logfile, das z.B. den Namen der angeforderten Datei, Ihre IP-Adresse, Datum und Uhrzeit des Abrufs, übertragene Datenmenge und den anfragenden Provider (Zugriffsdaten) enthält und den Abruf dokumentiert.
                        Diese Zugriffsdaten werden ausschließlich zum Zwecke der Sicherstellung eines störungsfreien Betriebs der Seite sowie der Verbesserung unseres Angebots ausgewertet. Dies dient gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO der Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden berechtigten Interessen an einer korrekten Darstellung unseres Angebots. Alle Zugriffsdaten werden spätestens sieben Tage nach Ende Ihres Seitenbesuchs gelöscht.
                        <br/>
                        Hostingdienstleistungen durch einen Drittanbieter
                        Im Rahmen einer Verarbeitung in unserem Auftrag erbringt ein Drittanbieter für uns die Dienste zum Hosting und zur Darstellung der Webseite. Dies dient der Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden berechtigten Interessen an einer korrekten Darstellung unseres Angebots. Alle Daten, die im Rahmen der Nutzung dieser Webseite oder in dafür vorgesehenen Formularen im Onlineshop wie folgend beschrieben erhoben werden, werden auf seinen Servern verarbeitet. Eine Verarbeitung auf anderen Servern findet nur in dem hier erläuterten Rahmen statt.
                        Dieser Dienstleister sitzt innerhalb eines Landes der Europäischen Union oder des Europäischen Wirtschaftsraums.

                        <h3>
                            2. Datenerhebung und -verwendung zur Vertragsabwicklung und bei Eröffnung eines Kundenkontos
                        </h3>
                        Wir erheben personenbezogene Daten, wenn Sie uns diese im Rahmen Ihrer Bestellung, bei einer Kontaktaufnahme mit uns (z.B. per Kontaktformular oder E-Mail) oder bei Eröffnung eines Kundenkontos freiwillig mitteilen. Pflichtfelder werden als solche gekennzeichnet, da wir in diesen Fällen die Daten zwingend zur Vertragsabwicklung, bzw. zur Bearbeitung Ihrer Kontaktaufnahme oder Eröffnung des Kundenkontos benötigen und Sie ohne deren Angabe die Bestellung und/ oder die Kontoeröffnung nicht abschließen, bzw. die Kontaktaufnahme nicht versenden können. Welche Daten erhoben werden, ist aus den jeweiligen Eingabeformularen ersichtlich. Wir verwenden die von ihnen mitgeteilten Daten gemäß Art. 6 Abs. 1 S. 1 lit. b DSGVO zur Vertragsabwicklung und Bearbeitung Ihrer Anfragen. Nach vollständiger Abwicklung des Vertrages oder Löschung Ihres Kundenkontos werden Ihre Daten für die weitere Verarbeitung eingeschränkt und nach Ablauf der steuer- und handelsrechtlichen Aufbewahrungsfristen gelöscht, sofern Sie nicht ausdrücklich in eine weitere Nutzung Ihrer Daten eingewilligt haben oder wir uns eine darüber hinausgehende Datenverwendung vorbehalten, die gesetzlich erlaubt ist und über die wir Sie in dieser Erklärung informieren. Die Löschung Ihres Kundenkontos ist jederzeit möglich und kann entweder durch eine Nachricht an die unten beschriebene Kontaktmöglichkeit oder über eine dafür vorgesehene Funktion im Kundenkonto erfolgen.

                        <h3>3. Datenweitergabe</h3>
                        Zur Vertragserfüllung gemäß Art. 6 Abs. 1 S. 1 lit. b DSGVO geben wir Ihre Daten an das mit der Lieferung beauftragte Versandunternehmen weiter, soweit dies zur Lieferung bestellter Waren erforderlich ist. Je nach dem, welchen Zahlungsdienstleister Sie im Bestellprozess auswählen, geben wir zur Abwicklung von Zahlungen die hierfür erhobenen Zahlungsdaten an das mit der Zahlung beauftragte Kreditinstitut und ggf. von uns beauftragte Zahlungsdienstleister weiter bzw. an den ausgewählten Zahlungsdienst. Zum Teil erheben die ausgewählten Zahlungsdienstleister diese Daten auch selbst, soweit Sie dort ein Konto anlegen. In diesem Fall müssen Sie sich im Bestellprozess mit Ihren Zugangsdaten bei dem Zahlungsdienstleister anmelden. Es gilt insoweit die Datenschutzerklärung des jeweiligen Zahlungsdienstleisters.

                        <h3>4. E-Mail-Newsletter und Postwerbung</h3>
                        E-Mail-Werbung mit Anmeldung zum Newsletter
                        Wenn Sie sich zu unserem Newsletter anmelden, verwenden wir die hierfür erforderlichen oder gesondert von Ihnen mitgeteilten Daten, um Ihnen regelmäßig unseren E-Mail-Newsletter aufgrund Ihrer Einwilligung gemäß Art. 6 Abs. 1 S. 1 lit. a DSGVO zuzusenden.
                        Die Abmeldung vom Newsletter ist jederzeit möglich und kann entweder durch eine Nachricht an die unten beschriebene Kontaktmöglichkeit oder über einen dafür vorgesehenen Link im Newsletter erfolgen. Nach Abmeldung löschen wir Ihre E-Mail-Adresse, soweit Sie nicht ausdrücklich in eine weitere Nutzung Ihrer Daten eingewilligt haben oder wir uns eine darüber hinausgehende Datenverwendung vorbehalten, die gesetzlich erlaubt ist und über die wir Sie in dieser Erklärung informieren.
                        Der Newsletter wird im Rahmen einer Verarbeitung in unserem Auftrag durch einen Dienstleister versendet, an den wir Ihre E-Mail-Adresse hierzu weitergeben.
                        Dieser Dienstleister sitzt innerhalb eines Landes der Europäischen Union oder des Europäischen Wirtschaftsraums.
                        <br/><br/>
                        Postwerbung und Ihr Widerspruchsrecht
                        Darüber hinaus behalten wir uns vor, Ihren Vor- und Nachnamen sowie Ihre Postanschrift für eigene Werbezwecke zu nutzen, z.B. zur Zusendung von interessanten Angeboten und Informationen zu unseren Produkten per Briefpost. Dies dient der Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden berechtigten Interessen an einer werblichen Ansprache unserer Kunden gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO.
                        <h3>5. Cookies und Webanalyse</h3>
                        Um den Besuch unserer Website attraktiv zu gestalten und die Nutzung bestimmter Funktionen zu ermöglichen, um passende Produkte anzuzeigen oder zur Marktforschung verwenden wir auf verschiedenen Seiten sogenannte Cookies. Dies dient der Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden berechtigten Interessen an einer optimierten Darstellung unseres Angebots gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO. Cookies sind kleine Textdateien, die automatisch auf Ihrem Endgerät gespeichert werden. Einige der von uns verwendeten Cookies werden nach Ende der Browser-Sitzung, also nach Schließen Ihres Browsers, wieder gelöscht (sog. Sitzungs-Cookies). Andere Cookies verbleiben auf Ihrem Endgerät und ermöglichen uns, Ihren Browser beim nächsten Besuch wiederzuerkennen (persistente Cookies). Die Dauer der Speicherung können Sie der Übersicht in den Cookie-Einstellungen Ihres Webbrowsers entnehmen. Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und einzeln über deren Annahme entscheiden oder die Annahme von Cookies für bestimmte Fälle oder generell ausschließen. Jeder Browser unterscheidet sich in der Art, wie er die Cookie-Einstellungen verwaltet. Diese ist in dem Hilfemenü jedes Browsers beschrieben, welches Ihnen erläutert, wie Sie Ihre Cookie-Einstellungen ändern können. Diese finden Sie für die jeweiligen Browser unter den folgenden Links:
                        Internet Explorer™: https://windows.microsoft.com/de-DE/windows-vista/Block-or-allow-cookies
                        Safari™: https://support.apple.com/kb/ph21411?locale=de_DE
                        Chrome™: https://support.google.com/chrome/bin/answer.py?hl=de&hlrm=en&answer=95647
                        Firefox™ https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen
                        Opera™ : https://help.opera.com/Windows/10.20/de/cookies.html
                        Bei der Nichtannahme von Cookies kann die Funktionalität unserer Website eingeschränkt sein.
                        <br/><br/>
                        Einsatz von Google (Universal) Analytics zur Webanalyse<br/>
                        Zur Webseitenanalyse setzt diese Website Google (Universal) Analytics ein, einen Webanalysedienst der Google LLC (www.google.de). Dies dient der Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden berechtigten Interessen an einer optimierten Darstellung unseres Angebots gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO. Google (Universal) Analytics verwendet Methoden, die eine Analyse der Benutzung der Website durch Sie ermöglichen, wie zum Beispiel Cookies. Die automatisch erhobenen Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Durch die Aktivierung der IP-Anonymisierung auf dieser Webseite wird dabei die IP-Adresse vor der Übermittlung innerhalb der Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte anonymisierte IP-Adresse wird grundsätzlich nicht mit anderen Daten von Google zusammengeführt. Nach Zweckfortfall und Ende des Einsatzes von Google Analytics durch uns werden die in diesem Zusammenhang erhobenen Daten gelöscht.
                        Die Google LLC hat ihren Hauptsitz in den USA und ist zertifiziert unter dem EU-US-Privacy Shield. Ein aktuelles Zertifikat kann hier eingesehen werden. Aufgrund dieses Abkommens zwischen den USA und der Europäischen Kommission hat letztere für unter dem Privacy Shield zertifizierte Unternehmen ein angemessenes Datenschutzniveau festgestellt.
                        Sie können die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren: https://tools.google.com/dlpage/gaoptout?hl=de
                        Alternativ zum Browser-Plugin können Sie diesen Link klicken, um die Erfassung durch Google Analytics auf dieser Website zukünftig zu verhindern. Dabei wird ein Opt-Out-Cookie auf Ihrem Endgerät abgelegt. Löschen Sie Ihre Cookies, müssen Sie den Link erneut klicken.

                        <h3>6. Kontaktmöglichkeiten und Ihre Rechte</h3>
                        Als Betroffener haben Sie folgende Rechte:
                        •	gemäß Art. 15 DSGVO das Recht, in dem dort bezeichneten Umfang Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen;
                        •	gemäß Art. 16 DSGVO das Recht, unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen;
                        •	gemäß Art. 17 DSGVO das Recht, die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen, soweit nicht die weitere Verarbeitung
                        – zur Ausübung des Rechts auf freie Meinungsäußerung und Information;
                        – zur Erfüllung einer rechtlichen Verpflichtung;
                        – aus Gründen des öffentlichen Interesses oder
                        – zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
                        erforderlich ist;
                        •	gemäß Art. 18 DSGVO das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, soweit
                        – die Richtigkeit der Daten von Ihnen bestritten wird;
                        – die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung ablehnen;
                        – wir die Daten nicht mehr benötigen, Sie diese jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen oder
                        – Sie gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben;
                        •	gemäß Art. 20 DSGVO das Recht, Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesebaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen;
                        •	gemäß Art. 77 DSGVO das Recht, sich bei einer Aufsichtsbehörde zu beschweren. In der Regel können Sie sich hierfür an die Aufsichtsbehörde Ihres üblichen Aufenthaltsortes oder Arbeitsplatzes oder unseres Unternehmenssitzes wenden.
                        Bei Fragen zur Erhebung, Verarbeitung oder Nutzung Ihrer personenbezogenen Daten, bei Auskünften, Berichtigung, Sperrung oder Löschung von Daten sowie Widerruf erteilter Einwilligungen oder Widerspruch gegen eine bestimmte Datenverwendung wenden Sie sich bitte direkt an uns über die Kontaktdaten in unserem Impressum.

                        <h3>Widerspruchsrecht</h3>
                        Soweit wir zur Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden berechtigten Interessen personenbezogene Daten wie oben erläutert verarbeiten, können Sie dieser Verarbeitung mit Wirkung für die Zukunft widersprechen. Erfolgt die Verarbeitung zu Zwecken des Direktmarketings, können Sie dieses Recht jederzeit wie oben beschrieben ausüben. Soweit die Verarbeitung zu anderen Zwecken erfolgt, steht Ihnen ein Widerspruchsrecht nur bei Vorliegen von Gründen, die sich aus Ihrer besonderen Situation ergeben, zu.
                        Nach Ausübung Ihres Widerspruchsrechts werden wir Ihre personenbezogenen Daten nicht weiter zu diesen Zwecken verarbeiten, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder wenn die Verarbeitung der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen dient.
                        Dies gilt nicht, wenn die Verarbeitung zu Zwecken des Direktmarketings erfolgt. Dann werden wir Ihre personenbezogenen Daten nicht weiter zu diesem Zweck verarbeiten.

                        </div>
                    </Modal>

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.state.save_data2}
                                onChange={handleChange2}
                                name="checkedB"
                                color="primary"
                            />
                        }
                        label="Ich stimme zu, die Buchungsinformation an meine angegebene E-Mail-Adresse zu senden."
                    />
                    {
                        showError ?
                            <Alert severity="error">{errorText}</Alert>
                            :
                            null
                    }
                </Box>
            default:
                return 'Unknown step';
        }
    }

    setActiveStep = (step) => {
        this.setState({activeStep: step})
    }

    // The render method contains the JSX code which will be compiled to HTML.
    render() {
        const {step, isLoading, skipped, activeStep, allowDates, errorText, showError} = this.state;
        const steps = this.getSteps();

        const isStepOptional = (step) => {
            return step === 3;
        };

        const setActiveStep = (step) => {
            this.setState({activeStep: step, step: step})
        }

        const isStepSkipped = (step) => {
            return skipped.has(step);
        };

        const validateEmail = (email) => {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        }

        const handleNext = () => {

            if(activeStep == 1 && this.state.userlist.length == this.state.counter_users-1) {

                if(this.state.email == "" || this.state.firstname == "" || this.state.lastname == "" || this.state.d == "" || this.state.street == "" || this.state.zip == "" || this.state.city == "") {
                    this.setState({showError: true, errorText: 'Bitte fülle alle Pflichtfelder aus'})
                    const inner = this;
                    setTimeout(function(){
                        inner.setState({showError: false, errorText: ''})
                    }, 2000);
                    return false;
                }

                if(!validateEmail(this.state.email)) {
                    this.setState({showError: true, errorText: 'Bitte trage eine valide E-Mail Adresse ein'})
                    const inner = this;
                    setTimeout(function(){
                        inner.setState({showError: false, errorText: ''})
                    }, 2000);
                    return false;
                }

                if(this.state.email != this.state.email_repeat) {
                    this.setState({showError: true, errorText: 'Deine E-Mail Adresse weicht ab'})
                    const inner = this;
                    setTimeout(function(){
                        inner.setState({showError: false, errorText: ''})
                    }, 2000);
                    return false;
                }


                if(this.state.save_data1 != true) {

                    this.setState({showError: true, errorText: 'bitte akzeptiere die Datenschutzbestimmungen'})
                    const inner = this;
                    setTimeout(function(){
                        inner.setState({showError: false, errorText: ''})
                    }, 2000);
                    return false;
                }

                if(this.state.save_data2 != true) {

                    this.setState({showError: true, errorText: 'bitte akzeptiere den Versand der Buchungsdaten'})
                    const inner = this;
                    setTimeout(function(){
                        inner.setState({showError: false, errorText: ''})
                    }, 2000);
                    return false;
                }




                this.state.userlist.push({
                        email: this.state.email,
                        firstname: this.state.firstname,
                        pin: this.state.pin,
                        lastname: this.state.lastname,
                        bday: this.state.y + "-" + this.state.m+"-"+this.state.d,
                        street: this.state.street,
                        zip: this.state.zip,
                        city: this.state.city,
                        phone: this.state.phone,
                        save_data: this.state.save_data
                    }
                )


                const d = this.state.save_data;
                this.setState({
                    userlist: this.state.userlist,
                    email: '',
                    email_repeat: '',
                    phone: '',
                    firstname: '',
                    pin: '',
                    lastname: '',
                    bday: '',
                    street: '',
                    zip: '',
                    city: '',
                    save_data: false
                }, function(){

                    if(this.state.save_data) {
                        localStorage.setItem('users', JSON.stringify(this.state.userlist));
                    }else{
                        localStorage.setItem('users', null);
                    }
                });

                const headers = {
                    //'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                };

                // NOTE Post to HTTPS only in production
                axios.post(Helper.url+Helper.suburl+'/booking/create', {
                    userlist: this.state.userlist,
                    coronaapp: this.state.coronaapp,
                    selected_time: this.state.selected_time,
                    selected_day: this.state.selected_day,
                }, {
                    headers: headers
                }).then((response) => {
                    this.setState({isLoading: false})
                    this.setState({isLoading: false, qrlink: response.data[0].qr})
                }).catch((errormsg) => {
                    console.log(errormsg);
                    if(typeof errormsg.response == "undefined") {
                        this.setState({
                            isLoading: false,
                            error: true
                        });
                    }else{
                        if(errormsg.response.status===404){

                            this.setState({
                                isLoading: false,
                                error: true
                            });
                        }else{
                            //this.setAlertErrorVisible();
                            this.setState({
                                isLoading: false,
                                error: true
                            });
                        }
                    }

                });

                let newSkipped = skipped;
                if (isStepSkipped(activeStep)) {
                    newSkipped = new Set(newSkipped.values());
                    newSkipped.delete(activeStep);
                }

                this.setActiveStep(this.state.activeStep + 1);
                this.setState({skipped: newSkipped})
            }else if(activeStep == 1 && this.state.userlist.length < this.state.counter_users) {

                if(this.state.email == "" || this.state.firstname == "" || this.state.lastname == "" || this.state.bday == "" || this.state.street == "" || this.state.zip == "" || this.state.city == "") {
                    this.setState({showError: true, errorText: 'Bitte fülle alle Pflichtfelder aus'})
                    const inner = this;
                    setTimeout(function(){
                        inner.setState({showError: false, errorText: ''})
                    }, 2000);
                    return false;
                }


                if(this.state.save_data1 != true) {

                    this.setState({showError: true, errorText: 'bitte akzeptiere die Datenschutzbestimmungen'})
                    const inner = this;
                    setTimeout(function(){
                        inner.setState({showError: false, errorText: ''})
                    }, 2000);
                    return false;
                }

                if(this.state.email != this.state.email_repeat) {
                    this.setState({showError: true, errorText: 'Deine E-Mail Adresse weicht ab'})
                    const inner = this;
                    setTimeout(function(){
                        inner.setState({showError: false, errorText: ''})
                    }, 2000);
                    return false;
                }

                this.state.userlist.push({
                        email: this.state.email,
                        email_repeat: this.state.email_repeat,
                        phone: this.state.phone,
                        firstname: this.state.firstname,
                        lastname: this.state.lastname,
                        pin: this.state.pin,
                        bday: this.state.bday,
                        street: this.state.street,
                        zip: this.state.zip,
                        city: this.state.city,
                        save_data: this.state.save_data
                    }
                )


                this.setState({
                    userlist: this.state.userlist,
                    email: '',
                    firstname: '',
                    email_repeat: '',
                    pin: '',
                    phone: '',
                    lastname: '',
                    bday: '',
                    street: '',
                    zip: '',
                    city: '',
                    save_data: false,
                    save_data2: false,
                    save_data1: false,
                    user_id: ""
                }, function() {

                });


                /*
                                this.setState({isLoading: true})
                                const headers = {
                                    //'Authorization': 'Bearer ' + token,
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                };
                                // NOTE Post to HTTPS only in production
                                axios.post(Helper.url+Helper.suburl+'/booking/create', {
                                    userlist: this.state.userlist,
                                    selected_time: this.state.selected_time,
                                    selected_day: this.state.selected_day,
                                }, {
                                    headers: headers
                                }).then((response) => {
                                    this.setState({isLoading: false})
                                    this.setState({isLoading: false, qrlink: response.data[0].qr})
                                }).catch((errormsg) => {
                                    console.log(errormsg);
                                    if(typeof errormsg.response == "undefined") {
                                        this.setState({
                                            isLoading: false,
                                            error: true
                                        });
                                    }else{
                                        if(errormsg.response.status===404){
                                            this.setState({
                                                isLoading: false,
                                                error: true
                                            });
                                        }else{
                                            //this.setAlertErrorVisible();
                                            this.setState({
                                                isLoading: false,
                                                error: true
                                            });
                                        }
                                    }
                                }); */
            }else{

                if(activeStep == 0) {

                    if(this.state.selected_time == null) {
                        this.setState({showError: true, errorText: 'bitte wähle einen Termin aus'})
                        const inner = this;
                        setTimeout(function(){
                            inner.setState({showError: false, errorText: ''})
                        }, 2000);
                        return false;
                    }
                }else if(activeStep == 1) {


                    if(this.state.save_data1 != true) {

                        this.setState({showError: true, errorText: 'bitte akzeptiere die Datenschutzbestimmungen'})
                        const inner = this;
                        setTimeout(function(){
                            inner.setState({showError: false, errorText: ''})
                        }, 2000);
                        return false;
                    }

                    this.setState({isLoading: true})

                    const headers = {
                        //'Authorization': 'Bearer ' + token,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    };

                    // NOTE Post to HTTPS only in production
                    axios.post(Helper.url+Helper.suburl+'/booking/create', {
                        userlist: this.state.userlist,
                        selected_time: this.state.selected_time,
                        selected_day: this.state.selected_day,
                    }, {
                        headers: headers
                    }).then((response) => {
                        this.setState({isLoading: false})
                        this.setState({isLoading: false, qrlink: response.data[0].qr})
                    }).catch((errormsg) => {
                        console.log(errormsg);
                        if(typeof errormsg.response == "undefined") {
                            this.setState({
                                isLoading: false,
                                error: true
                            });
                        }else{
                            if(errormsg.response.status===404){

                                this.setState({
                                    isLoading: false,
                                    error: true
                                });
                            }else{
                                //this.setAlertErrorVisible();
                                this.setState({
                                    isLoading: false,
                                    error: true
                                });
                            }
                        }

                    });
                }

                let newSkipped = skipped;
                if (isStepSkipped(activeStep)) {
                    newSkipped = new Set(newSkipped.values());
                    newSkipped.delete(activeStep);
                }

                this.setActiveStep(this.state.activeStep + 1);
                this.setState({skipped: newSkipped})
            }

        };

        const handleBack = () => {
            this.setActiveStep(this.state.activeStep - 1);
        };

        const handleSkip = () => {
            if (!isStepOptional(activeStep)) {
                // You probably want to guard against something like this,
                // it should never occur unless someone's actively trying to break something.
                throw new Error("You can't skip a step that isn't optional.");
            }

            this.setActiveStep(this.state.activeStep + 1);
            this.setSkipped((prevSkipped) => {
                const newSkipped = new Set(prevSkipped.values());
                newSkipped.add(activeStep);
                return newSkipped;
            });
        };

        const handleReset = () => {
            //this.setActiveStep(0);
            window.location.href = "/";
        };
        if(isLoading == true) {
            return '';
        }
        if(allowDates == null) {
            return '';
        }

        const handleVerify = (e) => {

            console.log("handleVerifyhandleVerify",e)
        }

        return (
            <div className={classes.root}>
                <Box className={"red"} style={{backgroundColor: Helper.secondary}}></Box>
                <Box className={"content"}>

                    <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};
                            if (isStepOptional(index)) {
                                labelProps.optional = <Typography variant="caption">Optional</Typography>;
                            }
                            if (isStepSkipped(index)) {
                                stepProps.completed = false;
                            }
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    <div>


                        {activeStep === steps.length ? (
                            <div>
                                {
                                    this.state.isLoading ?
                                        <div style={{textAlign: "center", marginTop: "50%"}}>
                                            <CircularProgress disableShrink />
                                        </div>
                                        :
                                        <Box>
                                            <div style={{textAlign: 'center'}}>
                                                <a href={"/"}><img src={successlogo} alt="" className={"logo"} style={{width: 150}}/></a>

                                                <Typography className={classes.instructions}>
                                                    Vielen Dank. Dein Termin wurde gebucht. Du erhälst in wenigen Minuten eine Bestätigungsemail.
                                                </Typography>
                                                <a href={"/"}><img src={this.state.qrlink} alt="" className={"logo"} style={{width: 150}}/></a>
                                                <Box style={{marginTop: 20}}>
                                                    <Button onClick={handleReset} className={classes.button}>
                                                        Schließen
                                                    </Button>
                                                </Box>
                                            </div>
                                        </Box>
                                }
                            </div>
                        ) : (
                            <div>

                                <Typography className={classes.instructions}>{this.getStepContent(activeStep)}</Typography>

                                <div>
                                    {activeStep === (steps.length-1) && this.state.captcha_public != "" ?
                                        <GoogleReCaptchaProvider reCaptchaKey={this.state.captcha_public} handleVerifyhandleVerify={"de"}>
                                            <GoogleReCaptcha onVerify={handleVerify} />
                                        </GoogleReCaptchaProvider>
                                        : null}
                                </div>
                                <div style={{padding: 20, paddingLeft: 40}}>

                                    <Box style={{float: 'left'}}>
                                        <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                                            Zurück
                                        </Button>
                                    </Box>
                                    <Box style={{float: 'right'}}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleNext}
                                            className={"goon"}
                                        >
                                            {activeStep === steps.length - 1 ? 'Bestätigen' : 'Weiter'}
                                        </Button>
                                    </Box>
                                    <Box style={{clear: 'both'}}></Box>
                                </div>
                            </div>
                        )}
                    </div>

                </Box>
            </div>
        )
    }
}

export default withStyles(useStyles)(Booking);
