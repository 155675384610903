import React, {Component} from 'react';
import logo from '../../public/logo.png'
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import ListItem from '@material-ui/core/ListItem';
import { Link as RouterLink } from 'react-router-dom';

const MehrLink = React.forwardRef((props, ref) => <RouterLink innerRef={ref} {...props} />);
function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}

class Footer extends Component {

    state = {
        token: localStorage.getItem('access_token')
    };


    render() {

        if(window.innerWidth < 900) {
            const flexContainer = {
                display: 'inline-flex',
                flexDirection: 'row',
                padding: 0,
            };

            return (
                <div className="foot-top-bar">
                    <List component="nav" aria-label="secondary mailbox folders" style={flexContainer}>
                        <ListItemLink onClick={(event) => window.location="/"}className={this.state.active =='home'? 'active': 'inactive'} style={{flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                            <ListItemText style={{fontSize: 14}}>
                                Startseite
                            </ListItemText>
                        </ListItemLink>
                        <ListItemLink onClick={(event) => window.location="/buchen?person=1"}  className={this.state.active =='auftraege'? 'active': 'inactive'} style={{flexDirection: 'column'}}>
                            <ListItemText style={{fontSize: 14}}>
                                Buchen
                            </ListItemText>
                        </ListItemLink>

                        <ListItemLink onClick={(event) => window.location="/result"}  className={this.state.active =='chat'? 'active': 'inactive'} style={{flexDirection: 'column'}}>
                            <ListItemText style={{fontSize: 14}}>
                                Ergebnis
                            </ListItemText>
                        </ListItemLink>

                        <ListItemLink onClick={(event) => window.location="/impressum"}  className={this.state.active =='chat'? 'active': 'inactive'} style={{flexDirection: 'column'}}>
                            <ListItemText style={{fontSize: 14}}>
                                Impressum
                            </ListItemText>
                        </ListItemLink>

                        {
                            this.state.token == null ?
                                <ListItemLink onClick={(event) => window.location="/login"}  style={{flexDirection: 'column', backgroundColor: '#bbb'}}>
                                    <ListItemText style={{fontSize: 14, color: '#fff'}}>
                                        <span style={{color: '#fff'}}>Anmelden</span>
                                    </ListItemText>
                                </ListItemLink>
                                :
                                this.state.token.length < 10 ?
                                    <ListItemLink  onClick={(event) => window.location="/login"} style={{flexDirection: 'column', backgroundColor: '#bbb'}}>
                                        <ListItemText style={{fontSize: 14, color: '#fff'}}>
                                            <span style={{color: '#fff'}}>Anmelden</span>
                                        </ListItemText>
                                    </ListItemLink>
                                    :
                                    <ListItemLink onClick={(event) => window.location="/account"} to={{pathname: '/account'}} style={{flexDirection: 'column', backgroundColor: '#bbb'}}>
                                        <ListItemText style={{fontSize: 14, color: '#fff'}}>
                                            <span style={{color: '#fff'}}>Konto</span>
                                        </ListItemText>
                                    </ListItemLink>

                        }
                    </List>
                </div>
            );
        } else {
           return <footer>
                <div className="footer">
                    <a href={"/"}><img src={logo} alt="Logo" className={"logo"} /></a>
                    <nav className="top-bar">

                    </nav>

                </div>
            </footer>
        }
    }
}

export default (Footer);
