import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import bl from '../public/bl-icon.png';
import termin from '../public/termin.png';
import ok from '../public/5402431-128.png';
import Helper from "../components/helper/helper";
import axios from "axios";
import ReactMarkdown from "react-markdown";
const useStyles = theme => ({

});
const classes = useStyles;


class home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            results: null,
            isLoaded: true,
            isDisconnected: false,
            counter_users: 1
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillUnmount() {
        window.removeEventListener('online', this.handleConnectionChange);
        window.removeEventListener('offline', this.handleConnectionChange);
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    handleConnectionChange = () => {
        const condition = navigator.onLine ? 'online' : 'offline';
        console.log(condition);
        if (condition === 'online') {

        }else{
            this.setState({ isDisconnected: true });
        }
    }

    updateDimensions() {
        this.setState({ innerwidth: window.innerWidth});
    }

    componentDidMount() {

        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };

        // NOTE Post to HTTPS only in production
        axios.get(Helper.url+Helper.suburl+'/global/content/home', {
            headers: headers
        }).then((response) => {
            this.setState({isLoaded: false, results:response.data.content})
        }).catch((errormsg) => {
            this.setState({isLoaded: false})
        });

        this.handleConnectionChange();
        window.addEventListener('online', this.handleConnectionChange);
        window.addEventListener('offline', this.handleConnectionChange);
        window.addEventListener("resize", this.updateDimensions.bind(this));

    }

    handleSubmit() {

        window.location.href = "/buchen/" + this.state.counter_users;
    }
    // The render method contains the JSX code which will be compiled to HTML.
    render() {

        const handleChangeCounterUsers = (event) => {
            this.setState({
                counter_users: event.target.value,
            });
        };

        const {counter_users} = this.state;
        return (
            <Box>
                <Box className={"background"} style={{height: 376, width: '100%'}}>

                </Box>
                <Box className={"red"} style={{backgroundColor: Helper.secondary}}></Box>
                <p style={{textAlign: 'center', fontWeight: "bold"}}>{Helper.address.street}, {Helper.address.zip} {Helper.address.city}<br/>{Helper.address.helper}<br/></p>
                <p style={{textAlign: 'center', fontWeight: "bold"}}>
                    {Helper.opening_date}<br/>Öffnungszeiten<br/>{Helper.opening_times}
<br /><br />
					<a href="tel:015734847238">015734847238</a>
<br/>
						<a href="mailto:info@testzentrum-nordheim.de">info@testzentrum-nordheim.de</a>
                </p>
                <form className={"former"} noValidate onSubmit={this.handleSubmit} action={"buchen"}>
                    <Box className={"subformer"}>
                        <div style={{display: 'none'}}>
                        <Box className={"former-box"} style={{float:"none"}}>
                            <span className={"former-box-booking-txt"}>Termin buchen</span>
                        </Box>

                        <Box className={"former-box"}>
                            <Box className={"former-box-select"}>
                                <Select
                                    native
                                    value={counter_users}
                                    onChange={handleChangeCounterUsers}
                                    inputProps={{
                                        name: 'person',
                                        id: 'age-native-simple',
                                    }}
                                >
                                    <option value={1}>1 Personen</option>
                                    <option value={2}>2 Personen</option>
                                    <option value={3}>3 Personen</option>
                                    <option value={4}>4 Personen</option>
                                </Select>
                            </Box>
                        </Box>
                        </div>
                        <Box className={"former-box"} style={{float:"none", paddingRight: 0}}>
                            <Button variant="contained" color="primary" className={"former-box-submit"} type={"submit"} style={{marginBottom    : window.innerWidth < 900 ? 10: 0,marginRight: window.innerWidth < 900 ? 0:10}}>
                                 Termin buchen
                            </Button>
                        </Box>
                        <Box style={{clear: 'both'}}></Box>
                    </Box>
                </form>
                <Box className={"content"}>
                    <Box style={{marginTop:10}}>

                        {this.state.results == null ? null : <ReactMarkdown>{this.state.results.content}</ReactMarkdown>}
                    </Box>
                    <div style={{paddingTop: 20, paddingBottom: 20}}>
                        <Box>
                            <div style={{width: 20, float: 'left', marginRight: 10, }}>
                                <img src={ok} style={{width: 25}} />
                            </div>
                            <div style={{float: 'left', width: '80%',fontSize:13, paddingLeft: 10, paddingTop: window.innerWidth < 900 ? 0 : 0}}>Wir verwenden ausschließlich zugelassene Tests (PEI evaluiert, BfArM zugelassen) mit einer hohen Zuverlässigkeit.</div>
                        </Box>
                        <div style={{clear: 'both'}}></div>
                        <Box style={{marginTop:10}}>
                            <div style={{width: 20, float: 'left', marginRight: 10, }}>
                                <img src={ok} style={{width: 25}} />
                            </div>
                            <div style={{float: 'left', width: '80%',fontSize:13, paddingLeft: 10, paddingTop: window.innerWidth < 900 ? 0 : 0}}>Buche schnell und einfach deinen kostenlosen Corona-Testtermin online oder nutze den Sofort Check-In.</div>
                        </Box>
                        <div style={{clear: 'both'}}></div>
                        <Box style={{marginTop:10}}>
                            <div style={{width: 20, float: 'left', marginRight: 10, }}>
                                <img src={ok} style={{width: 25}} />
                            </div>
                            <div style={{float: 'left', width: '80%',fontSize:13, paddingLeft: 10, paddingTop: window.innerWidth < 900 ? 0 : 0}}>Bringe zur Identifizierung deinen QR-Code und deinen Ausweis mit.</div>
                        </Box>
                        <div style={{clear: 'both'}}></div>

                        <Box style={{marginTop:10}}>
                            <div style={{width: 20, float: 'left', marginRight: 10, }}>
                                <img src={ok} style={{width: 25}} />
                            </div>
                            <div style={{float: 'left', width: '80%',fontSize:13, paddingLeft: 10, paddingTop: window.innerWidth < 900 ? 0 : 0}}>Die Durchführung erfolgt durch medizinisch ausgebildetes Personal.</div>
                        </Box>
                        <div style={{clear: 'both'}}></div>
                        <Box style={{marginTop:10}}>
                            <div style={{width: 20, float: 'left', marginRight: 10, }}>
                                <img src={ok} style={{width: 25}} />
                            </div>
                            <div style={{float: 'left', width: '80%',fontSize:13, paddingLeft: 10, paddingTop: window.innerWidth < 900 ? 0 : 0}}>Du erhälst dein Testergebnis nach 15-20 Minuten sicher per E-Mail.</div>
                        </Box>
                        <div style={{clear: 'both'}}></div>
                    </div>


                    {
                        (true) ? null :

                        (window.innerWidth < 900)
                            ?
                            <Box>
                                <Box className={"changer"}>

                                    <Box className={"changerTxt"}>
                                        <h1 style={{backgroundColor: Helper.secondary, width: 50, height: 50, borderRadius: 100, color: '#fff' , margin: 'auto', marginBottom: 10}}>1</h1>
                                        <Typography variant="body1" component="body">
                                            Buche schnell und einfach deinen kostenlosen Corona-Testtermin online.
                                        </Typography>
                                    </Box>
                                    <Box style={{clear: 'both'}}></Box>
                                </Box>

                                <Box className={"changer"}>
                                    <Box className={"changerTxt"}>
                                        <h1 style={{backgroundColor: Helper.secondary, width: 50, height: 50, borderRadius: 100, color: '#fff' , margin: 'auto', marginBottom: 10}}>2</h1>
                                        <Typography variant="body1" component="body">
                                            Komm zum Termin vorbei, zeige deinen QR-Code vor und lasse dich von unserem medizinischen Personal kostenlos testen.
                                        </Typography>
                                    </Box>
                                    <Box style={{clear: 'both'}}></Box>
                                </Box>

                                <Box className={"changer"}>
                                    <Box className={"changerTxt"}>
                                        <h1 style={{backgroundColor: Helper.secondary, width: 50, height: 50, borderRadius: 100, color: '#fff' , margin: 'auto', marginBottom: 10}}>3</h1>
                                        <Typography variant="body1" component="body">
                                            Du erhälst das Testergebnis nach kurzer Prüfung per E-Mail.
                                        </Typography>
                                    </Box>
                                    <Box style={{clear: 'both'}}></Box>
                                </Box>
                            </Box>
                            :
                            <Box>
                                <Box className={"changer"}>

                                    <Box className={"changerTxt"}>
                                        <Typography variant="h4" component="h3" gutterBottom>
                                            Corona-Testtermin buchen
                                        </Typography>
                                        <Typography variant="body1" component="body">
                                            Buche schnell und einfach deinen kostenlosen Corona-Testtermin online.
                                        </Typography>
                                    </Box>
                                    <Box style={{clear: 'both'}}></Box>
                                </Box>

                                <Box className={"changer"}>

                                    <Box className={"changerTxt"}>
                                        <Typography variant="h4" component="h3" gutterBottom>
                                            Corona-Testtermin durchführen
                                        </Typography>
                                        <Typography variant="body1" component="body">
                                            Komm zum Termin vorbei,
                                        </Typography>
                                    </Box>
                                    <Box className={"changerImg"}>
                                        <img src={termin} />
                                    </Box>
                                    <Box style={{clear: 'both'}}></Box>
                                </Box>

                                <Box className={"changer"}>
                                    <Box className={"changerTxt"}>
                                        <Typography variant="h4" component="h3" gutterBottom>
                                            Testergebnis online abrufen
                                        </Typography>
                                        <Typography variant="body1" component="body">
                                            Du erhälst dein Testergebnis nach kurzer Prüfung per E-Mail
                                        </Typography>
                                    </Box>
                                    <Box style={{clear: 'both'}}></Box>
                                </Box>
                            </Box>
                    }


                </Box>
                <iframe
                    src={Helper.address.map}
                    width="100%" height="200" allowFullScreen="" loading="lazy"></iframe>

                <form className={"former"} noValidate onSubmit={this.handleSubmit} action={"buchen"}>
                    <Box className={"subformer"}>
                        <Box className={"former-box"} style={{display: 'none'}}>
                            <span className={"former-box-booking-txt"}>Termin buchen</span>
                        </Box>
                        <Box className={"former-box"} style={{display: 'none'}}>
                            <Box className={"former-box-select"}>
                                <Select
                                    native
                                    value={counter_users}
                                    onChange={handleChangeCounterUsers}
                                    inputProps={{
                                        name: 'person',
                                        id: 'age-native-simple',
                                    }}
                                >
                                    <option value={1}>1 Personen</option>
                                    <option value={2}>2 Personen</option>
                                    <option value={3}>3 Personen</option>
                                    <option value={4}>4 Personen</option>
                                </Select>
                            </Box>
                        </Box>
                        <Box className={"former-box"}>
                            <Button variant="contained" color="primary" className={"former-box-submit"} onClick={() => window.location.href = 'https://www.google.de/maps/dir//'+Helper.address.street+', '+Helper.address.zip+' '+Helper.address.city}>
                                Route starten
                            </Button>
                        </Box>
                        <Box style={{clear: 'both'}}></Box>
                    </Box>
                </form>
                            </Box>
        );
    }
}

export default withStyles(useStyles)(home);
