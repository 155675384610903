import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';

import axios from 'axios';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Helper from "../components/helper/helper";
import moment from "moment";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const MehrLink = React.forwardRef((props, ref) => <RouterLink innerRef={ref} {...props} />);
function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}


var options = {}

const useStyles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        overflow: 'hidden',
        alignItems: 'center',
        justifyContent: 'center',

    },
    gridList: {
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
    titleBar: {
        background: "transparent"
    },

    icon: {
        backgroundColor: "#005D66",
        color: "#fff",
        padding: "5px",
        margin: "12px"
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        background: '#fff',
        padding: '20px'
    },
    inputField: {
        width: '100%',
        borderBottom: 0,
        backgroundColor: "#e9e9e9"
    },
    boxView: {
        width: '49%',
        marginRight: '1%',
        marginBottom: 20
    },
    boxViewFull: {
        width: '100%',
        marginRight: '1%',
        marginBottom: 20
    },
    boxViewsmall: {
        width: '33.5%',
        marginRight: '1%',
    },
    boxViewsmaller: {
        width: '14.5%',
        marginRight: '1%',
    },
    btnAdd: {
        padding: 0,
        margin: 0,
        fontSize: 35,
        width: 35,
        height: 35,
        minHeight: 0,
        minWidth: 0,
        backgroundColor: '#eb690b'
    },
    btnSubmit: {
        backgroundColor: '#eb690b',
        color: '#fff'
    }
});
const classes = useStyles;


class OrderDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: true,
            timestamp: 0,
            isDisconnected: false,
            scriptisLoaded: false,
            innerwidth: window.innerWidth,
            email: null,
            password: null,
            error: false,
            result: 'No result',
            token: localStorage.getItem('access_token'),
            id: this.props.match.params.id,
            order: null,
            phone: '',
            email_repeat: '',
            firstname: '',
            lastname: '',
            bday: '',
            street: '',
            zip: '',
            city: '',
            userid: 0,
            labor: localStorage.getItem('labor') != 'Ja' ? 0: 1,
            send: false,
			tests: []
        };

        if(this.state.token == null) {
            window.location.href = "/login";
        }
        if(this.state.token.toString().length < 10) {
            window.location.href = "/login";
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillUnmount() {
        window.removeEventListener('online', this.handleConnectionChange);
        window.removeEventListener('offline', this.handleConnectionChange);
        window.removeEventListener("resize", this.updateDimensions.bind(this));
        clearInterval(this.interval);
    }

    handleConnectionChange = () => {
        const condition = navigator.onLine ? 'online' : 'offline';
        console.log(condition);
        if (condition === 'online') {

        }else{
            this.setState({ isDisconnected: true });
        }
    }



    updateDimensions() {
        this.setState({ innerwidth: window.innerWidth});
    }

    loadOrders() {

        const headers = {
            'Authorization': 'Bearer ' + this.state.token,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };

        // NOTE Post to HTTPS only in production


        axios.get(Helper.url+Helper.suburl+'/booking/show/'+this.state.id, {
            headers: headers
        }).then((response) => {

            this.setState({
                isLoaded: false,
                order: response.data, email: response.data.user.email,
                phone: response.data.user.profile.telephone,
                firstname: response.data.user.profile.firstname,
                lastname: response.data.user.profile.lastname,
                bday: response.data.user.profile.bday,
                street: response.data.user.profile.street,
                zip: response.data.user.profile.zip,
                city: response.data.user.profile.city,
                userid: response.data.user.id,
				tests: response.data.tests
            })
        }).catch((errormsg) => {
            console.log(errormsg);
        });

    }

    componentDidMount() {

        this.handleConnectionChange();
        window.addEventListener('online', this.handleConnectionChange);
        window.addEventListener('offline', this.handleConnectionChange);
        window.addEventListener("resize", this.updateDimensions.bind(this));

        this.loadOrders();

    }

    handleSubmit1(){
        //event.preventDefault();
        localStorage.setItem('access_token', "x");
        window.location.href = "/";
    }

    handleStatus(status){
        this.setState({
            send: true,
        });
        const headers = {
            'Authorization': 'Bearer ' + this.state.token,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };

        // NOTE Post to HTTPS only in production
        axios.post(Helper.url+Helper.suburl+'/booking/handle/'+ this.state.id, {
            status: status
        }, {
            headers: headers
        }).then((response) => {
            if(response.status === 200){
                alert("gespeichert");
                this.setState({
                    send: false,
                });
                 //window.location.reload();
                window.location.href = "/opencustomer";
            }else{
                this.setState({
                    send: false,
                });
            }
        }).catch((errormsg) => {
            this.setState({
                loading: false,
                error: true,
                send: false
            });
            if(typeof errormsg.response == "undefined") {
                this.setState({
                    loading: false,
                    error: true,
                    send: false,
                });
            }else{
                if(errormsg.response.status===404){

                    this.setState({
                        loading: false,
                        error: true,
                        send: false,
                    });
                }else{
                    //this.setAlertErrorVisible();

                    this.setState({
                        loading: false,
                        error: true,
                        send: false,
                    });
                }
            }

        });
    }

    handleSubmit(event){
        event.preventDefault();
        /*this.setState({
            loading: true,
        });*/
        const headers = {
            'Authorization': 'Bearer ' + this.state.token,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };

        // NOTE Post to HTTPS only in production
        axios.post(Helper.url+Helper.suburl+'/user/profile/update/'+ this.state.userid, {
            email: this.state.email,
            phone: this.state.phone,
            telephone: this.state.phone,
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            bday: this.state.bday,
            street: this.state.street,
            zip: this.state.zip,
            city: this.state.city
        }, {
            headers: headers
        }).then((response) => {
            if(response.status === 200){
                alert("gespeichert");

               // window.location.href = "/";
            }else{
                //alert(response.status);
            }
        }).catch((errormsg) => {
            console.log(errormsg);
            if(typeof errormsg.response == "undefined") {
                this.setState({
                    loading: false,
                    error: true
                });
            }else{
                if(errormsg.response.status===404){

                    this.setState({
                        loading: false,
                        error: true
                    });
                }else{
                    //this.setAlertErrorVisible();

                    this.setState({
                        loading: false,
                        error: true
                    });
                }
            }

        });
    }

    onChange(value, key) {
        this.setState( {
            [key]: value
        })
    }

goCheckin() {

	this.setState({isLoading: true})

        const headers = {
            //'Authorization': 'Bearer ' + token,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };

        // NOTE Post to HTTPS only in production
        axios.post(Helper.url+Helper.suburl+'/booking/createcustome', {
            userlist: [
                {
                    test_id: this.state.test_id,
                    email: (this.state.email == "") ? this.makeid(5) + "@"+window.location.hostname+"": this.state.email,
                    firstname: this.state.firstname,
                    lastname: this.state.lastname,
                    bday: this.state.bday,
                    street: this.state.street,
                    zip: this.state.zip,
                    city: this.state.city,
                    phone: this.state.phone,
                }
            ],
            selected_day: moment().format("Y-MM-DD"),
            selected_time: moment().hours()+':'+moment().minutes(),
        }, {
            headers: headers
        }).then((response) => {
            this.setState({isLoading: false});

            window.location.href = "http://"+window.location.hostname+"/que/" + response.data[0].test_id;
            //localStorage.setItem('access_token', response.data.access_token);
        })

}

    print() {
        window.open("https://system."+window.location.hostname+"/api/image/"+this.state.order.user_id+"/get", "_blank");
        window.location.href = "/qu";
    }

    printPDF() {
        let na = Math.floor(Math.random() * 10) + '' + Math.floor(Math.random() * 10);
        let na1 = Math.floor(Math.random() * 10) + '' + Math.floor(Math.random() * 10)
        window.open("https://system."+window.location.hostname+"/api/pdf/"+na+this.state.order.user_id+na1+"/get", "_blank");
        window.location.href = "/qu";
    }
    // The render method contains the JSX code which will be compiled to HTML.
    render() {
        const { error, isLoaded} = this.state;
        const { classes } = this.props;

        const col = (this.state.innerwidth > 900) ? 8 : 2.5;

        if (isLoaded) {
            return <div style={{textAlign: "center", marginTop: "50%"}}>
                <CircularProgress disableShrink />
            </div>
        } else {
            const flexContainer = {

                padding: 0,
            };

            return (
                <div className={"content"}>
                    <Typography variant="h1" component="h3" gutterBottom>
                        Details zu Ticket: {this.state.id}

                    </Typography>
                    <form onSubmit={this.handleSubmit} className={classes.root} noValidate autoComplete="off" style={{paddingTop:20}}>
                        <Box className={"boxViewFull"}>
                            <TextField id="filled-basic" fullWidth label="E-Mail" variant="outlined" className={classes.inputField} value={this.state.email}
                                       onChange={(e) => {this.onChange(e.target.value, 'email')}}/>
                        </Box>

                        <Box className={"boxViewFull"}>
                            <TextField id="filled-basic" fullWidth label="Telefon" variant="outlined" className={classes.inputField} value={this.state.phone}
                                       onChange={(e) => {this.onChange(e.target.value, 'phone')}}/>
                        </Box>
                        <Box style={{clear: 'both'}}></Box>

                        <Box className={"boxViewFull"}>
                            <TextField id="filled-basic" fullWidth label="Vorname" variant="outlined" className={classes.inputField} value={this.state.firstname}
                                       onChange={(e) => {this.onChange(e.target.value, 'firstname')}}/>
                        </Box>

                        <Box className={"boxViewFull"}>
                            <TextField id="filled-basic" fullWidth label="Nachname" variant="outlined" className={classes.inputField} value={this.state.lastname}
                                       onChange={(e) => {this.onChange(e.target.value, 'lastname')}}/>
                        </Box>


                        <Box className={"boxViewFull"}>
                            <TextField id="filled-basic" fullWidth label="Geburtstag" variant="outlined" className={classes.inputField} value={this.state.bday}
                                       onChange={(e) => {this.onChange(e.target.value, 'bday')}}/>
                        </Box>
                        <Box style={{clear: 'both'}}></Box>
                        <Box className={"boxViewFull"}>
                            <TextField id="filled-basic" fullWidth label="Straße & Hausnummer" variant="outlined" className={classes.inputField} value={this.state.street}
                                       onChange={(e) => {this.onChange(e.target.value, 'street')}}/>
                        </Box>

                        <Box className={"boxViewFull"}>
                            <TextField id="filled-basic" fullWidth label="PLZ" variant="outlined" className={classes.inputField} value={this.state.zip}
                                       onChange={(e) => {this.onChange(e.target.value, 'zip')}}/>
                        </Box>

                        <Box className={"boxViewFull"}>
                            <TextField id="filled-basic" fullWidth label="Ort" variant="outlined" className={classes.inputField} value={this.state.city}
                                       onChange={(e) => {this.onChange(e.target.value, 'city')}}/>
                        </Box>
                        <Box style={{clear: 'both'}}></Box>

<Box className={"boxViewFull"}>
                            <Button variant="contained" type="button" fullWidth color="default" onClick={()=> this.goCheckin()}>Neu einchecken</Button>
</Box>
                        <Box className={"boxViewFull"}>
                            <Button variant="contained" type="button" fullWidth color="default" onClick={()=> this.print()}>nur QR drucken</Button>
                        </Box>
                        <Box className={"boxViewFull"}>
                            <Button variant="contained" type="button" fullWidth color="default" onClick={()=> this.printPDF()}>mit Namen drucken</Button>
                        </Box>

                        <Box className={"boxViewFull"}>
                            <Button variant="contained" type="submit" fullWidth color="primary" className={classes.btnSubmit}>Speichern</Button>
                        </Box>
                        {
                            this.state.send ? null :
                            this.state.order.status == "open" || this.state.order.status == "done" || this.state.order.status == "send" || !this.state.labor ?
                            null
                            :
                                <div style={{width: "100%", textAlign: "center" }}>

                                    <Typography variant="h1" component="h3" gutterBottom>
                                        Status Änderung
                                    </Typography>
                                    <div style={{width: "100%"}}></div>
                                    <Box className={"boxViewFull"}>
                                        <Button variant="contained" type="button" fullWidth color="secondary" onClick={() =>  (window.confirm("Bist du sicher das du den Test als positiv makieren möchtest?")) ? this.handleStatus(true) : ""}>Test positiv</Button>
                                    </Box>

                                    <Box className={"boxViewFull"}>
                                        <Button variant="contained" type="button" fullWidth style={{backgroundColor: 'green'}} onClick={() => (window.confirm("Bist du sicher das du den Test als negativ makieren möchtest?")) ? this.handleStatus(false) : ''}>Test negativ</Button>
                                    </Box>



                                    <Box className={"boxViewFull"}>
                                        <Button variant="contained" type="button" fullWidth style={{backgroundColor: 'grey'}} onClick={() => (window.confirm("Bist du sicher das du den Test stornieren möchtest?")) ? this.handleStatus("none") : ''}>Test stornieren</Button>
                                    </Box>
                                </div>
                        }



                    </form>
                    <h3>Historie zu den Tests</h3>
                    {
                        this.state.tests.map(item =>
                            (item.status == "done") ?
                                <p>{new Date(item.updated_at).toLocaleDateString("de-DE")}: Wird erstellt</p>
                                :
                            <p><a target={"_blank"} style={{color: '#000', fontSize: 20, textDecoration: "none"}} href={""+Helper.url+"/api/result/"+item.id+"/get"}>{new Date(item.updated_at).toLocaleDateString("de-DE")}: {item.test_id}</a></p>
                        )
                    }
                </div>
            )
        }

    }
}

export default withStyles(useStyles)(OrderDetails);
