import React, {Component} from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Link as RouterLink } from 'react-router-dom';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import logo from '../../public/logo.png'
import Helper from './helper';
const MehrLink = React.forwardRef((props, ref) => <RouterLink innerRef={ref} {...props} />);
function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}

class Header extends Component {

    state = {
        show: (localStorage.getItem('access_token') != null) ? true:false,
        active: "home",
        token: localStorage.getItem('access_token')
    };

    render() {

        if(window.innerWidth > 900) {

            const flexContainer = {
                display: 'inline-flex',
                flexDirection: 'row',
                padding: 0,
            };

            return (
                <div className={"header"}>
                    <header>

                            <a href={"/"}><img src={logo} alt="Logo" className={"logo"} /></a>



                        {
                            false ? null :
                                <div className={"header-sub"} style={{justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
                                    <List component="nav" aria-label="secondary mailbox folders" style={flexContainer}>
                                        <ListItemLink  onClick={(event) => window.location="/"} className={this.state.active =='home'? 'active': 'inactive'} style={{flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                            <ListItemText style={{fontSize: 14}}>
                                                Startseite
                                            </ListItemText>
                                        </ListItemLink>

                                        <ListItemLink  onClick={(event) => window.location="/result"} sclassName={this.state.active =='result'? 'active': 'inactive'} style={{flexDirection: 'column'}}>
                                            <ListItemText style={{fontSize: 14}}>
                                                Ergebnis einsehen
                                            </ListItemText>
                                        </ListItemLink>
                                        <ListItemLink  onClick={(event) => window.location="/buchen?person=1"} sclassName={this.state.active =='auftraege'? 'active': 'inactive'} style={{flexDirection: 'column'}}>
                                            <ListItemText style={{fontSize: 14}}>
                                                Termin buchen
                                            </ListItemText>
                                        </ListItemLink>

                                        <ListItemLink  onClick={(event) => window.location="/impressum"} className={this.state.active =='chat'? 'active': 'inactive'} style={{flexDirection: 'column'}}>
                                            <ListItemText style={{fontSize: 14}}>
                                                Impressum
                                            </ListItemText>
                                        </ListItemLink>

                                        {
                                            this.state.token == null ?
                                                <ListItemLink  onClick={(event) => window.location="/login"} style={{flexDirection: 'column', backgroundColor: Helper.secondary}}>
                                                    <ListItemText style={{fontSize: 14, color: '#fff'}}>
                                                        <span style={{color: '#fff'}}>Anmelden</span>
                                                    </ListItemText>
                                                </ListItemLink>
                                                :
                                                    this.state.token.length < 10 ?
                                                        <ListItemLink  onClick={(event) => window.location="/login"} style={{flexDirection: 'column', backgroundColor: Helper.secondary}}>
                                                            <ListItemText style={{fontSize: 14, color: '#fff'}}>
                                                                <span style={{color: '#fff'}}>Anmelden</span>
                                                            </ListItemText>
                                                        </ListItemLink>
                                                    :
                                                        <ListItemLink onClick={(event) => window.location="/account"} style={{flexDirection: 'column', backgroundColor: Helper.secondary}}>
                                                            <ListItemText style={{fontSize: 14, color: '#fff'}}>
                                                                <span style={{color: '#fff'}}>Mein Konto</span>
                                                            </ListItemText>
                                                        </ListItemLink>

                                        }

                                    </List>
                                </div>
                        }
                    </header>
                </div>
            );
        } else {
            return (
                <div className={"header"}>
                    <header>
                        <p style={{padding: 0, margin: 0}}>
                            <a href={"/"}><img src={logo} alt="Logo" className={"logo"} /></a>
                        </p>
                    </header>
                </div>);
        }
    }
}

export default (Header);
