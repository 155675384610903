import React from "react";
import { Switch } from "react-router-dom";
import Route from "./Route";
import Home from '../pages/home';
import Booking from "../pages/booking";
import Login from "../pages/login";
import Scanner from "../pages/scanner";
import Account from "../pages/account";
import Order from "../pages/order";
import OrderDetails from "../pages/orderDetails";
import Settings from "../pages/settings";
import Impressum from "../pages/impressum";
import Customer from "../pages/customer";
import CustomerDetails from "../pages/CustomerDetails";
import Opencustomer from "../pages/opencustomer";
import Findcustomer from "../pages/findcustomer";
import Users from "../pages/users";
import Openappointment from "../pages/openappointment";
import Bookingnow from "../pages/bookingnow";
import Checkin from "../pages/checkin";
import Result from "../pages/result";
import Testresult from "../pages/testresult";
import Contents from "../pages/contents";
import Content from "../pages/content";

export default function Routes() {

    return (
        <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/buchen" exact component={Booking} />
            <Route path="/buchen/:person" exact component={Booking} />
            <Route path="/login" exact component={Login} />
            <Route path="/scanner" exact component={Scanner} />
            <Route path="/account" exact component={Account} />
            <Route path="/que/:id" exact component={OrderDetails} />
            <Route path="/qu" exact component={Order} />
            <Route path="/impressum" exact component={Impressum} />
            <Route path="/settingsdata" exact component={Settings} />
            <Route path="/customer" exact component={Customer} />
                <Route path="/customer/:id" exact component={CustomerDetails} />
            <Route path="/users" exact component={Users} />
            <Route path="/opencustomer" exact component={Opencustomer} />
                <Route path="/result" exact component={Result} />
            <Route path="/openappointment" exact component={Openappointment} />
            <Route path="/findcustomer" exact component={Findcustomer} />
            <Route path="/sofort" exact component={Bookingnow} />
            <Route path="/checkin" exact component={Checkin} />
            <Route path="/contents" exact component={Contents} />
            <Route path="/content/:slug" exact component={Content} />


            <Route path="/testergebnis/:uuid" exact component={Testresult} />
            {/* redirect user to SignIn p age if route does not exist and user is not authenticated */}
            <Route component={Home} />
        </Switch>
    );
}
