import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import axios from "axios";
import Helper from "../components/helper/helper";

const useStyles = theme => ({

});
const classes = useStyles;


class Testresult extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isDisconnected: false,
            testid: typeof this.props.match.params.uuid != "undefined" && this.props.match.params.uuid != "" ? this.props.match.params.uuid : '',
            loading: true,
            test: [],
            time: '',
            after24: false,
            after48: false,
            testkit: ""
        };

        console.log(this.props.match.params.uuid)
       // this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillUnmount() {
        window.removeEventListener('online', this.handleConnectionChange);
        window.removeEventListener('offline', this.handleConnectionChange);
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    handleConnectionChange = () => {
        const condition = navigator.onLine ? 'online' : 'offline';
        console.log(condition);
        if (condition === 'online') {

        }else{
            this.setState({ isDisconnected: true });
        }
    }

    updateDimensions() {
        this.setState({ innerwidth: window.innerWidth});
    }

    componentDidMount() {

        this.handleConnectionChange();
        window.addEventListener('online', this.handleConnectionChange);
        window.addEventListener('offline', this.handleConnectionChange);
        window.addEventListener("resize", this.updateDimensions.bind(this));

        const headers = {
            //'Authorization': 'Bearer ' + token,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };

        axios.get(Helper.url+Helper.suburl+'/checkresult/'+this.state.testid, {
            headers: headers
        }).then((response) => {
            let after24 = false;
            let after48 = false;
            if(response.data.time.indexOf('Tage') != -1) {
                let std = parseInt(response.data.time.substring(0, 2));
                if(std == 1) {
                    after24 = true;
                }else if(std > 1) {
                    after48 = true;
                }
            }

            this.setState({after24: after24, after48: after48, test: response.data.test, time: response.data.time, testkit: response.data.testkit, loading: false})
        })
    }

    onChange(value, key) {
        this.setState( {
            [key]: value
        })
    }


    render() {
        const {token} = this.state;

        if(this.state.loading) {
            return (
                <Box style={{backgroundColor: '#018749', padding: 20, textAlign: 'center'}}>
                    Suche nach Testergebnis ...
                </Box>
            )
        }

        if(this.state.after48) {
           return <Box>
                <Box style={{backgroundColor: 'darkred', padding: 20, textAlign: 'center'}}>
                    <h1 style={{color: '#fff', padding: 0, margin: 5, fontSize: 38, marginBottom: 10}}>{this.state.test.result ? 'POSITIV' : 'NEGATIV'}</h1>
                    <Box style={{backgroundColor: '#333', padding: 10, color: '#ddd', width: this.state.time.indexOf("und") != -1 ? 320: 200, textAlign: 'center', margin: 'auto'}}>
                        Getestet <span style={{color: 'red'}}>vor {this.state.time}</span>
                        <br/>
                        Der Test ist <b>abgelaufen</b> und ist nicht mehr gültig.
                    </Box>
                </Box>
            </Box>
        }

        return (
            <Box>
                <Box style={{backgroundColor: this.state.after24 ? 'darkorange': this.state.test.result ? 'darkred' : '#018749', padding: 20, textAlign: 'center'}}>
                    <h1 style={{color: this.state.test.result ? '#fff': '#000', padding: 0, margin: 5, fontSize: 38, marginBottom: 10}}>{this.state.test.result ? 'POSITIV' : 'NEGATIV'}</h1>
                    <Box style={{backgroundColor: '#333', padding: 10, color: '#ddd', width: this.state.time.indexOf("und") != -1 ? 320: 200, textAlign: 'center', margin: 'auto'}}>
                        Getestet <span style={{color: this.state.after24 ? 'darkorange': this.state.test.result ? 'red' : '#018749'}}>vor {this.state.time}</span>
                    </Box>
                </Box>

                <Box style={{maxWidth: 800, margin: 'auto', backgroundColor: this.state.after24 ? 'darkorange': this.state.test.result ? 'darkred' : '#018749', color: this.state.test.result ? '#fff': '#000',}}>
                    <Box style={{padding: 20, marginTop: 10 }}>
                        <p style={{margin: 0}}>Test-ID</p>
                        <h3 style={{margin: 0, marginBottom: 10}}>{this.state.testid}</h3>
                        <p style={{margin: 0}}>Testtermin</p>
                        <h3 style={{margin: 0, marginBottom: 10}}>{new Date(this.state.test.updated_at).toLocaleString("de-De")}</h3>
                        <p style={{margin: 0}}>Corona Antigen-Schnelltest</p>
                        <h3 style={{margin: 0, marginBottom: 10}}>{this.state.testkit}</h3>
                    </Box>
                    <Box style={{color: this.state.test.result ? '#fff': '#000',marginBottom: 40, backgroundColor: this.state.after24 ? 'darkorange': this.state.test.result ? 'darkred' : '#018749', padding: 20, paddingTop: 0, marginTop: 0}}>
                        <p>
                            Sie wurden <u>{this.state.test.result ? 'POSITIV' : 'NEGATIV'}</u> auf Covid-19 getestet.
                        </p>
                        {this.state.test.result ?
                            <p>
                                Melden Sie sich bitte beim nächsten Gesundheitsamt.
                            </p>
                            :
                            null
                        }
                        {this.state.after24 ?
                            <p style={{fontWeight: 'bold'}}>
                                Dein Testergebnis ist nicht mehr in jedem Bundesland gültig. Buche schnell und einfach deinen nächsten Termin oder besuche uns in unserem Testzentrum.
                            </p>
                            :
                            null
                        }
                        <p>
                            Beachten Sie dennoch weiterhin die AHA+L+A Regeln:
                        </p>
                        <ul>
                            <li><b>A</b>stand halten</li>
                            <li><b>H</b>ygiene beachten</li>
                            <li>im <b>A</b>lltag Maske tragen</li>
                            <li>regelmäßig <b>l</b>üften</li>
                            <li>Corona-Warn-<b>A</b>pp</li>
                        </ul>
                    </Box>
                </Box>
            </Box>
        );
    }
}

export default withStyles(useStyles)(Testresult);
